import React from "react"
import Seo from "../components/seo/Seo"
import Contracts from "../components/modules/contracts/Contracts"

const ContractsPage = () => {
  return <Contracts />
}

export default ContractsPage

export const Head = () => <Seo title={'Contratos'} />;
const fonts = {
    greycliffCF: `"Greycliff CF", "sans-serif"`,
    jeko: `Jeko, "sans-serif"`,
    jekoVariable: `"Jeko Variable", "sans-serif"`,
    // Font weights
    fontThin: 100,
    fontExtraLight: 200, // Extra Light, Ultra Light
    fontLight: 300,
    fontRegular: 400, // Normal, Book, Regular
    fontMedium: 500,
    fontSemiBold: 600, // Semi Bold, Demi Bold
    fontBold: 700,
    fontExtraBold: 800, // Extra Bold, Ultra Bold
    fontBlack: 900, // Black, Heavy
  }
  const colors = {
    // Colors
    white: '#FFF',
    black: '#000',
    pink: '#FB3898',
    pinkLight: '#FCCDBD',
    blue: '#0178ED',
    blueLight: '#4DB2F6',
    blueTitle: '#007CEC',
    blueDark: '#244168',
    yellow: '#F4D660',
    aqua: '#2CCCD3',
    green: '#56C271',
    redLight: '#F83821',
    orange: '#ED6B00',
    gray: '#8C8C8C',
    grayLight: '#E9E9E9',
    grayLight2: '#F2F2F2',
    grayBox: '#F5F6F6',
    footerBgIconLink: '#0085EE',
    footerBgSocial: '#006FEA',
  }
  
  const themeDefault = {
    themeName: 'Default Theme',
    // Font
    font: fonts.jeko,
    palette: {
      // BG Color
      bgColor: colors.white,
      textColor: colors.black,
      // Colours
      primary: colors.white,
    },
    colors,
    fonts,
    awesomegrid: {
      mediaQuery: 'only screen',
      columns: {
        xs: 4,
        sm: 8,
        md: 12,
        lg: 12,
        xl: 12,
      },
      gutterWidth: {
        xs: 1,
        sm: 1.5,
        md: 1.5,
        lg: 1.5,
        xl: 1.5,
      },
      paddingWidth: {
        xs: 1,
        sm: 2.625,
        md: 3.125,
        lg: 3.75,
        xl: 6.25,
      },
      container: {
        xs: 'full', // 'full' = max-width: 100%
        sm: 'full', // 40.5 width = 648px
        md: 'full', // 56.5 width = 904px
        lg: 'full', // 68.3125 width = 1093px
        xl: 120, // 90 width = 1440px / 120 max-width: 1920px
      },
      breakpoints: {
        xs: 1,
        sm: 48, // 768px
        md: 64, // 1024px
        lg: 85, // 1360px
        xl: 120, // 1920px
      },
    },
  }
  
  module.exports = themeDefault
  
import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const StepChargeStyle = styled.div`

  .m-step-charge__button-back{
    margin-bottom: 30px;
  }

  .m-step-charge__container-contract-pay {
    margin: 40px auto 30px auto;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #e0f0fe;
    max-width: 450px;

    .m-step-charge__container-contract {
      p {
        &:last-child {
          color: ${props => props.theme.colors.blue};
          font-size: 22px;
          font-weight: ${props => props.theme.fonts.fontBold};
        }
      }
    }

    .m-step-charge__container-pay {
      text-align: right;
      p {
        &:last-child {
          color: ${props => props.theme.colors.pink};
          font-size: 22px;
          font-weight: ${props => props.theme.fonts.fontBold};
        }
      }
    }

    .m-step-charge__label {
      font-size: 16px;
      color: ${props => props.theme.colors.blueDark};
      margin-bottom: 12px;
    }
  }

  .m-step-charge__container-form {
    margin: 0 auto;
    max-width: 450px;
    .m-step-charge__payment-method {
      font-size: 22px;
      font-weight: ${props => props.theme.fonts.fontBold};
      color: ${props => props.theme.colors.blueDark};
      margin-bottom: 20px;
    }

    .m-step-charge__formulario {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .m-step-charge__wrapper-input-name {
        position: relative;
        width: 450px;

        .m-step-charge__label-optional-text {
          position: absolute;
          bottom: 0px;
          left: 0;
          color: ${props => props.theme.colors.blue};
          font-size: 13px;
          font-weight: ${props => props.theme.fonts.fontSemiBold};
        }
      }

      .m-step-charge__wrapper-input-card {
        width: 100%;
        position: relative;

        .m-step-charge__card-type--error,
        .m-step-charge__card-type--loading{
          position: absolute;
          bottom: 5px;
          left: 0;
          color: ${props => props.theme.colors.pink};
          font-size: 13px;
          font-weight: ${props => props.theme.fonts.fontSemiBold};
        }

        .m-step-charge__card-type--loading {
          color: ${props => props.theme.colors.blue};
        }
      }

     .m-step-charge__input{
        width: 100%;
        max-width: 380px;
      }

      .m-step-charge__container-last-row {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        .m-input__warning-icon {
          display: none;
        }
      }

      .m-step-charge__checkbox {
        margin-top: 15px;

        a {
          text-decoration: underline;
          color: ${({ theme }) => theme.colors.blue};
        }
      }
    }
  }

  .m-step-charge__container_button {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 100px;
    text-align: center;

    .m-step-charge__button {
      width: 258px;      
    }
  }

  @media ${device.sm} {
    .m-step-charge__container-contract-pay {
      width: 370px;
      margin: 40px auto;
    }

    .m-step-charge__container-form {
      width: 370px;
      margin: 0 auto;
      .m-step-charge__payment-method {
        font-size: 24px;
      }

      .m-step-charge__formulario {

        .m-step-charge__wrapper-input-name {
          width: 100%;
        }

        .m-step-charge__container-last-row .m-input__warning-icon {
          display: block;
        }
        .m-step-charge__checkbox {
          margin-top: 20px;
        }
      }
    }
  }

  @media ${device.md} {
    .m-step-charge__button-back{
      margin-bottom: 0px;
    } 

    .m-step-charge__container-contract-pay {
      margin-top: 60px;
    }

    .m-step-charge__container_button  .m-step-charge__button {
      width: 290px;      
    }
  }

  @media ${device.lg} {
    
    .m-step-charge__container-contract-pay {
      width: 430px;
    }
  
    .m-step-charge__container-form {
      width:430px;

      .m-step-charge__formulario {
        .m-step-charge__input{
          max-width: 430px;
        }
      }
    }
  }

  @media ${device.xl} {
  
  }
`

export default StepChargeStyle;

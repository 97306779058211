import React from "react"
import Seo from "../components/seo/Seo"
import RestorePassword from "../components/modules/restorePassword/RestorePassword"

const RestablecerContrasenaPage = () => {
  return <RestorePassword />
}

export default RestablecerContrasenaPage

export const Head = () => <Seo title={"Restablecer Contraseña"} />

import * as React from "react"

function SvgSuccess(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} fill="none">
      <g clipPath="url(#svg-check)">
        <path
          fill="#56C271"
          d="M45 90c24.853 0 45-20.147 45-45S69.853 0 45 0 0 20.147 0 45s20.147 45 45 45Z"
        />
        <path
          fill="#4AB364"
          d="M45.217.005H45c-24.852 0-45 20.147-45 45 0 24.854 20.148 45 45 45h.217v-90Z"
        />
        <path
          fill="#fff"
          d="M38.088 62.953 21.073 45.938a1.944 1.944 0 0 1 0-2.751l4.902-4.91a1.944 1.944 0 0 1 2.752 0l9.333 9.333a1.944 1.944 0 0 0 2.755 0l20.428-20.555a1.946 1.946 0 0 1 2.752-.008l4.925 4.893a1.947 1.947 0 0 1 .008 2.752L40.843 62.949a1.946 1.946 0 0 1-2.755.005Z"
        />
        <path
          fill="#CEF2F9"
          d="m45.195 43.203-4.375 4.402a1.946 1.946 0 0 1-2.755 0l-9.333-9.333a1.945 1.945 0 0 0-2.752 0l-4.908 4.91a1.943 1.943 0 0 0 0 2.751l17.016 17.02a1.943 1.943 0 0 0 2.755 0l4.352-4.376V43.203Z"
        />
      </g>
      <defs>
        <clipPath id="svg-check">
          <path fill="#fff" d="M0 0h90v90H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgSuccess

import styled, { css } from "styled-components"
import { device } from "../../../theme/mediaQueries"
import InputMask from "react-input-mask";

export const StyledInputContainer = styled.div`
    position: relative;
    margin-bottom: 30px;
    width: 260px;

    &.is-blue{
        input{
            color: ${props => props.theme.colors.blue};
        }
    }

    &.is-pink{
        input{
            color: ${props => props.theme.colors.pink};
        }
    }
`
export const StyledInputLabel = styled.label`
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    top: 0;
    left: 0;
    position: absolute;
    transform: ${props => (props.$active === "true" ? "translate(30px, 3px) scale(0.9)" : "translate(20px, 30px) scale(1)")};
    transform-origin: top left;
    z-index: 1;
    font-size: 14px;
    font-family: ${props => props.theme.fonts.jeko};
    font-weight: ${props => props.theme.fonts.fontSemiBold};
    color: ${props => (props.$active === "true" ? props.theme.colors.blueDark : props.theme.colors.black)};
    padding: 5px 5px;
    pointer-events: none;
    background-color: white;
    @media ${device.md} {
        font-size: 14px;
    }
    @media ${device.lg} {
        font-size: 16px;
        padding: 3px 5px;
        transform: ${props => (props.$active === "true" ? "translate(35px, 3px) scale(0.9)" : "translate(20px, 35px) scale(1)")};
    }
    @media ${device.xl} {
        font-size: 18px;
    }

    &.input-error--label{
        color: ${props => props.theme.colors.pink}
    }
`
export const StyledInputWrapper = styled.div`
    margin-top: 16px;
    position: relative;
    display: inline-block;
    width: 100%;

    .m-input__warning-icon{
        width: 20px;
        height: 20px;
        position: absolute;
        top: 18px;
        right: 20px;
        img{
            width: 20px;
            height: 20px;
        }

        @media ${device.lg} {
            top: 24px;
        }
    }

    .m-input__password-icon{
        width: 20px;
        height: 20px;
        position: absolute;
        top: 18px;
        right: 20px;

        button {
            align-items: center;
            background-color: transparent;
            border: none;
            display: flex;
            cursor: pointer;
            justify-content: center;
            height: 25px;
            padding: 0;
            width: 25px;
        }

        svg{
          fill: ${props => props.theme.colors.blue};
        }

        @media ${device.lg} {
            top: 24px;
        }
    }
`
const sharedInputStyled = css`
    font-weight: ${props => props.theme.fonts.fontSemiBold};
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 24px 0;
    width: 100%;
    border: 2px solid  ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.blueDark};
    margin: 0;
    display: block;
    min-width: 0;
    background: none;
    letter-spacing: inherit;
    -webkit-tap-highlight-color: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-left: 30px;
    background-color:  transparent;
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    &::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
        -moz-appearance:textfield;
        margin: 0
    }
    &::-webkit-outer-spin-button {
        display: none;
        -webkit-appearance: none;
        -moz-appearance:textfield;
        margin: 0
    }
    &:focus {
        outline: none;
    }

    @media ${device.md} {
        font-size: 14px;
    }
    @media ${device.lg} {
        font-size: 16px;
    }
    @media ${device.xl} {
        font-size: 18px;
    }

    &.input-error{
        border: 2px solid  ${props => props.theme.colors.pink}
    }
`

export const StyledInputText = styled.input`
    ${sharedInputStyled}
    min-height: 60px;
    position:relative;
    @media ${device.lg} {
      min-height: 70px;
    }
`

export const StyledTextArea = styled.textarea`
    ${sharedInputStyled}
    padding: 20px 0 0 30px;
    min-height: 95px;
    font-family: ${props => props.theme.fonts.jeko};
    font-weight: ${props => props.theme.fonts.fontExtraBold};
    resize: none;
`

export const StyledInputError = styled.p`
    font-size: 12px;
    color: ${props => props.theme.colors.pink};
    position: absolute;
    bottom: -22px;
    left: 8px;
    font-weight: ${props => props.theme.fonts.fontSemiBold};

    @media ${device.lg} {
        font-size: 13px;
    }
`

export const StyledInputMask = styled(InputMask)`
    ${sharedInputStyled}
    min-height: 60px;
    position:relative;
    @media ${device.lg} {
      min-height: 70px;
    }
`

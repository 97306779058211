import styled from "styled-components";
import { device } from "../../../theme/mediaQueries";

export const ModalCard = styled.div`
  margin: 0 auto;
  display: block;
  position: relative;
  width: 90%;
  height: 500px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;

  @media ${device.sm} {
    width: 450px;
    height: 550px;
  }

  @media ${device.lg} {
    width: 500px;
    height: 600px;
  }

  .m-modal-content__button{
    margin: 0 auto;
    display: flex;
    min-width: 250px;
  }
`

export const StyleModalImage = styled.div`
  text-align: center;
  svg{
      width: 90px;
      height: 90px;
      margin-top: 60px;
  }

  @media ${device.lg} {
    svg{
      width: 113px;
      height: 113px;
      margin-top: 76px;
    }
  }
   
`

export const StyleModalText = styled.div`
  width: 100%;
  padding: 45px 30px;
  text-align: center;
  .m-modal-content__title{
    color: ${props => props.theme.colors.blue};
    font-size: 26px;
    font-weight: ${props => props.theme.fonts.fontExtraBold};
    margin-bottom: 36px;
  }
  p{
    font-weight: ${props => props.theme.fonts.fontBold};
    max-width: 300px;
    margin: 0 auto;
  }

  @media ${device.sm} {
    .m-modal-content__title{
      font-size: 32px;
    }
  }

  @media ${device.lg} {
    padding: 55px 30px;
  }
`

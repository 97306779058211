import React from "react"
import { bool, func, string } from "prop-types"

import SvgAlert from "../svgs/SvgAlert"
import SvgSuccess from "../svgs/SvgSuccess"
import RoundedButton from "../buttons/RoundedButton"

import {
  ModalCard,
  StyleModalImage,
  StyleModalText,
} from "./CustomAlerts.style"


const CustomAlert = ({ onClose, title, description, buttonTitle, isError = false }) => {
  const classNameID = "m-modal-content"

  return (
    <ModalCard className={classNameID}>
      <StyleModalImage>
        { isError ? <SvgAlert /> : <SvgSuccess /> }
      </StyleModalImage>
      <StyleModalText>
        <h1 className={`${classNameID}__title`}>{title}</h1>
        <p className={`${classNameID}__description`}>{ description }</p>
      </StyleModalText>
      <RoundedButton className={`${classNameID}__button is-blue`} onClick={onClose} >
        {buttonTitle}
      </RoundedButton>
    </ModalCard>
  )
}

CustomAlert.propTypes = {
  onClose: func,
  title: string,
  description: string,
  buttonTitle: string,
  isError: bool,
}

CustomAlert.defaultProps = {
  onClose: () => { },
  title: "",
  description: "",
  buttonTitle: "",
  isError: false,
}

export default CustomAlert

import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { node, func } from "prop-types"

import ModalBackground from "./Modal.style"

const Modal = ({ children, onClose }) => {
  const classNameID = "m-modal"
  const [modalRoot, setModalRoot] = useState(null)

  useEffect(() => {
    document.querySelector("body").classList.add("body-hidden")

    const modal = document.getElementById("modal-root")
    setModalRoot(modal)

    return () => {
      document.querySelector("body").classList.remove("body-hidden")
    }
  }, [])

  return modalRoot
    ? ReactDOM.createPortal(
        <ModalBackground className={classNameID}>
          <div
            className={`${classNameID}__overlay`}
            onClick={onClose}
            onKeyDown={event => {
              if (event.key === "Enter" || event.key === " ") {
                onClose()
              }
            }}
            role="button"
          tabIndex="0"
          aria-label="Close modal"
          />
          <div className={`${classNameID}__content`}>{children}</div>
        </ModalBackground>,
        modalRoot
      )
    : null
}

Modal.propTypes = {
  children: node,
  onClose: func,
}

Modal.defaultProps = {
  children: null,
  onClose: () => {},
}

export default Modal

import axios from 'axios';

const baseUrl = process.env.GATSBY_BASE_URL

export const signUp = async (name, surnames, email, password, phoneNumber, accountNumber, label, meterId) => {
  try {
    // const response = await axios.get(`https://private-c26c0c-agsal.apiary-mock.com/v1/signup`,
    //   { headers: { 'Content-Type': 'application/json' } },
    // );

    const response = await axios.post(`${baseUrl}/api/v1/signup/`,
      {
        name, surnames, email, password, phoneNumber,
        account: { accountNumber, label, meterId }
      },
      { headers: { 'Content-Type': 'application/json' } },
    );

    const { data } = response;
 
    return data; 
  } catch (error) {
    throw error;
  }
};

export const verificationCode = async (phoneNumber) => {
  try {
    // const response = await axios.get(`https://private-c26c0c-agsal.apiary-mock.com/v1/signup/send-verification-code`,
    //   { headers: { 'Content-Type': 'application/json' } },
    // );

    const response = await axios.post(`${baseUrl}/api/v1/signup/send-verification-code`,
      { phoneNumber },
      { headers: { 'Content-Type': 'application/json' } },
    );
 
    return response; 
  } catch (error) {
    throw error;
  }
};

export const verify = async (verificationCode, phoneNumber) => {
  try {
    // const response = await axios.get(`https://private-c26c0c-agsal.apiary-mock.com/v1/signup/verify`,
    //   { headers: { 'Content-Type': 'application/json' } },
    // );

    const response = await axios.post(`${baseUrl}/api/v1/signup/verify`,
      {verificationCode, phoneNumber },
      { headers: { 'Content-Type': 'application/json' } },
    );
 
    return response; 
  } catch (error) {
    throw error;
  }
};

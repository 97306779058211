import React, { useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import SiteContext from "../../../context/site.context"

import validate from "../../../helpers/formValidationRules"
import useForm from "../../../hooks/useForm"

import TextTitle from "../blocks/title/TextTitle"
import InputText from "../../elements/inputs/InputText"
import RoundedButton from "../../elements/buttons/RoundedButton"
import UnderlineButton from "../../elements/buttons/UnderlineButton"
import Modal from "../modal/Modal"
import CustomAlert from "../../elements/alerts/CustomAlert"

import iconLoading from "../../../images/loading.png"

import { PasswordStyled } from "./RecoverPassword.style"
const RecoverPassword = () => {
  const classNameID = `m-recover-password`
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState("")

  const { loading, recoverPassword, error } = useContext(SiteContext)

  const data = {
    email: "",
  }

  const { inputs, handleInputChange, handleSubmit, errors } = useForm(
    data,
    formValidCallback,
    validate
  )

  async function formValidCallback(errorsForm) {
    if (Object.keys(errorsForm).length > 0) {
      return
    }
    const response = await recoverPassword(inputs.email)

    if (response && response.status === 200) {
      setMessage(
        "Se ha enviado un correo electrónico con las instrucciones para restablecer su contraseña."
      )
    } else {
      setMessage(response)
    }
    setShowModal(true)
  }

  return (
    <PasswordStyled className={classNameID}>
      <TextTitle textBlue="¿Olvidó su" textGreen="contraseña?" />
      <div className={`${classNameID}__container-description`}>
        <p>Recibirá instrucciones en su correo electrónico</p>
      </div>
      <div className={`${classNameID}__container-form`}>
        <form
          id="form-recover-password"
          noValidate
          className={`${classNameID}__formulario`}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <InputText
            id="email"
            name="email"
            className={`${classNameID}__input`}
            type="email"
            placeholder="*Tu Email"
            handleInputChange={handleInputChange}
            required
            error={errors.email}
          />
          <div className={`${classNameID}__container-button`}>
            <RoundedButton
              className={`${classNameID}__button--send is-blue has-animation`}
              type="submit"
              onClick={handleSubmit}
              isDisabled={loading}
            >
              {loading ? (
                <>
                  <img src={iconLoading} alt="loading" /> Enviando...
                </>
              ) : (
                "Enviar"
              )}
            </RoundedButton>
          </div>
        </form>
        <Link to="/login">
          <UnderlineButton className={`${classNameID}__button--cancelar`}>
            Cancelar
          </UnderlineButton>
        </Link>
      </div>
      {showModal && (
        <Modal onClose={() => {setShowModal(false)}}>
          {
            <CustomAlert
              onClose={() => {
                setShowModal(false)
                !error && navigate("/login")
              }}
              title={error ? "Error" : "¡Listo!"}
              description={message}
              buttonTitle="Aceptar"
              isError={error}
            />
          }
        </Modal>
      )}
    </PasswordStyled>
  )
}

export default RecoverPassword

import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { isLoggedIn } from "../../../services/auth"

import RoundedButton from "../../elements/buttons/RoundedButton"

import ModuleStyled from "./NotFound.style"

const NotFound = () => {
  const classNameID = `m-not-found`

  return (
    <ModuleStyled className={classNameID}>
      <div className={`${classNameID}__container`}>
        <div className={`${classNameID}__image`}>
          <StaticImage
            src="../../../images/404/not-found.png"
            alt="404"
            layout="fullWidth"
            placeholder="blurred"
            className={`${classNameID}__image`}
          />
        </div>
        <div className={`${classNameID}__image-xl`}>
          <StaticImage
            src="../../../images/404/not-found-xl.png"
            alt="404"
            layout="fullWidth"
            placeholder="blurred"
            className={`${classNameID}__image-xl`}
          />
        </div>
        <h1 className={`${classNameID}__title`}>La página no fue encontrada</h1>
        <p className={`${classNameID}__description`}>
          Lo sentimos, no podemos encontrar la página que busca
        </p>
        <RoundedButton
          onClick={() => {
            if (isLoggedIn()) {
              navigate("/cuenta")
            } else {
              navigate("/")
            }
          
          } }
          className={`${classNameID}__btn is-blue`}
        >
          Volver
        </RoundedButton>
      </div>
    </ModuleStyled>
  )
}

export default NotFound

import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
import SiteContext from "../../../context/site.context"
import { formatDate, formattedMoney } from "../../../hooks/useFormatStrings"

import TextTitle from "../blocks/title/TextTitle"
import UnderlineButton from "../../elements/buttons/UnderlineButton"
import PaymentVoucher  from "./PaymentVoucher"

import PaySuccessfulStyled from "./PaySuccessful.style"

const PaySuccessful = () => {
  const classNameID = `m-pay-successful`
  const { successDetailsPayment } = useContext(SiteContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const createRow = (label, value, className) => {
    return (
      <div className={`${classNameID}__summary-row`}>
        <p className={`${classNameID}__summary-name`}>{label}</p>
        <p className={`${classNameID}__summary-value ${className}`}>{value}</p>
      </div>
    )
  }

  const getSummary = () => {
    return (
      <div className={`${classNameID}__summary`}>
        {createRow("Pago:", formattedMoney(successDetailsPayment.amount), "is-pink")}
        {createRow("No. tarjeta :", `${successDetailsPayment.card}`, "")}
        {createRow("Fecha:", `${formatDate(successDetailsPayment.date)}`, "")}
        {createRow("Referencia:", `${successDetailsPayment.reference}`, "")}
        {createRow("No. autorización:", `${successDetailsPayment.authorizationCode}`, "")}
        {
          successDetailsPayment.reconnectionOrder !== null &&
          createRow("Orden de reconexión:", `${successDetailsPayment.reconnectionOrder}`, "")
        }
        
      </div>
    )
  }

  return (
    <PaySuccessfulStyled className={classNameID} >
      <TextTitle textBlue="Pago aplicado" textGreen="exitosamente" />
        {getSummary()}
      <div className={`${classNameID}__container_buttons`}>
        <PaymentVoucher />
        <Link to="/cuenta">
          <UnderlineButton>Finalizar</UnderlineButton>
        </Link>
      </div>
    </PaySuccessfulStyled>
  )
}

export default PaySuccessful

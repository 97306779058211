import styled from 'styled-components';
import { device } from '../../../../theme/mediaQueries';

const TextTitleStyled = styled.div`
  width: 100%;
  text-align: center;
  
  .m-text-title__primary {
    font-weight: ${({ theme }) => theme.fonts.fontExtraBold};
    font-size: 42px;
    color: ${({ theme }) => theme.colors.blueTitle};

    span {
      color: ${({ theme }) => theme.colors.green};
    }
  }

  @media ${device.sm} {
    .m-text-title__primary {
      font-size: 52px;
    }
  }

  @media ${device.md} {
    .m-text-title__primary {
      font-size: 62px;
    }
  }
`
export default TextTitleStyled;
import React from "react"
import { string, func, node, bool } from "prop-types"

import { ContainerButton, ContainerIcon, ButtonLink } from "./ButtonArrowLeft.style"
import SvgArrow from "../svgs/SvgArrow"

const ButtonArrowLeft = ({ children, onClick, isDisabled, className, to }) => {
  const classNameID = `m-arrow-button`

  if(to){
    return (
      <ButtonLink to={to} className={`${classNameID} ${className} ${isDisabled ? "is-disabled" : ""}`} onClick={onClick}>
        <ContainerIcon>
          <SvgArrow />
        </ContainerIcon>
        <p className={`${classNameID}__text`}>{children}</p>
      </ButtonLink>
    )
  }
  return (
    <ContainerButton className={`${classNameID} ${className} ${isDisabled ? "is-disabled" : ""}`} onClick={onClick}>
      <ContainerIcon>
        <SvgArrow />
      </ContainerIcon>
      <p className={`${classNameID}__text`}>{children}</p>
    </ContainerButton>
  )
}

ButtonArrowLeft.propTypes = {
  children: node.isRequired,
  className: string,
  onClick: func,
  isDisabled: bool,
  to: string
}

ButtonArrowLeft.defaultProps = {
  className: "",
  onClick: () => null,
  isDisabled: false,
  to:""
}

export default ButtonArrowLeft

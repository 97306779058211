import React, { useEffect } from "react"
import { ThemeProvider } from "styled-components"

import useSiteData from "../../hooks/useSiteData"
import SiteContext from "../../context/site.context"

import GlobalStyles from "../../theme/globalStyles"
import themeDefault from "../../theme/themeDefault"

import { StaticImage } from "gatsby-plugin-image"

import LayoutStyled from "./Layout.style"
import Header from "../modules/header/Header"
import Footer from "../modules/footer/Footer"

const Layout = ({ children }) => {
  const classNameID = `l-layout`
  const siteData = useSiteData()

  useEffect(() => { 
    window.scrollTo(0, 0)
  }, [])

  return (
    <SiteContext.Provider value={siteData}>
      <ThemeProvider theme={themeDefault}>
        <GlobalStyles />
        <LayoutStyled className={`${classNameID}`}>
        <StaticImage
          src="../../images/illustrations/ilustration_right.png"
          alt="Ilustracion"
          layout="fullWidth"
          placeholder="blurred"
          className={`${classNameID}__imageRight`}
        />
          <Header />
          <main id={`${classNameID}__main`}>
            <>
              <StaticImage
                src="../../images/illustrations/ilustration_left.png"
                alt="Ilustracion"
                layout="fullWidth"
                placeholder="blurred"
                className={`${classNameID}__imageLeft`}
              />
              {children}
            </>
          </main>
          <Footer />
          <div id="modal-root" />
        </LayoutStyled>
      </ThemeProvider>
    </SiteContext.Provider>
  )
}

export default Layout

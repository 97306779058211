import React, { useContext, useState } from "react"
import SiteContext from "../../../context/site.context"

import validate from "../../../helpers/formValidationRules"
import useForm from "../../../hooks/useForm"

import TextTitle from "../blocks/title/TextTitle"
import InputText from "../../elements/inputs/InputText"
import RoundedButton from "../../elements/buttons/RoundedButton"
import ButtonArrowLeft from "../../elements/buttons/ButtonArrowLeft"
import Modal from "../modal/Modal"
import CustomAlert from "../../elements/alerts/CustomAlert"

import iconLoading from "../../../images/loading.png"

import { ChanagePasswordStyled } from "./ChangePassword.style"
import { navigate } from "gatsby"

const ChangePassword = () => {
  const classNameID = `m-change-password`
  const { loading, changeUserPassword, error } = useContext(SiteContext)
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState("")

  const data = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  }

  const { inputs, handleInputChange, handleSubmit, errors } = useForm(
    data,
    formValidCallback,
    validate
  )

  async function formValidCallback(errorsForm) {
    if (Object.keys(errorsForm).length > 0) {
      return
    }

    const response = await changeUserPassword(
      inputs.currentPassword,
      inputs.password
    )

    if (response) {
      setMessage(response)
    }
    setShowModal(true)
  }

  const AlertError = error => {
    return (
      <CustomAlert
        onClose={() => {
          setShowModal(false)
        }}
        title="Error ¿Qué pasó?"
        description={error}
        buttonTitle="Intentar de nuevo"
        isError={true}
      />
    )
  }

  const AlertSuccess = () => {
    return (
      <CustomAlert
        onClose={() => {
          setShowModal(false)
          navigate("/cuenta")
        }}
        title="Contraseña actualizada"
        description="Su contraseña ha sido actualizada correctamente"
        buttonTitle="Aceptar"
      />
    )
  }

  return (
    <ChanagePasswordStyled className={classNameID}>
      <ButtonArrowLeft to="/cuenta" className={`${classNameID}__button-back`}>
        Regresar
      </ButtonArrowLeft>
      <TextTitle textBlue="Cambiar su" textGreen="contraseña" />
      <div className={`${classNameID}__container-form`}>
        <form
          id="form-recover-password"
          noValidate
          className={`${classNameID}__formulario`}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <InputText
            id="currentPassword"
            name="currentPassword"
            className={`${classNameID}__input`}
            type="password"
            placeholder="*Contraseña"
            handleInputChange={handleInputChange}
            required
            error={errors.currentPassword}
          />
          <InputText
            id="password"
            name="password"
            className={`${classNameID}__input`}
            type="password"
            placeholder="*Nueva Contraseña"
            handleInputChange={handleInputChange}
            required
            error={errors.password}
          />
          <InputText
            id="confirmPassword"
            name="confirmPassword"
            className={`${classNameID}__input`}
            type="password"
            placeholder="*Confirmar Contraseña"
            handleInputChange={handleInputChange}
            required
            error={errors.confirmPassword}
          />
          <div className={`${classNameID}__container-password-hint`} >
            <p>
              La contraseña debe tener al menos 8 caracteres, incluyendo
              mayúsculas, números y caracteres especiales.
            </p>
          </div>
          <div className={`${classNameID}__container-button`}>
            <RoundedButton
              className={`${classNameID}__button--send is-blue has-animation`}
              type="submit"
              onClick={handleSubmit}
              isDisabled={loading}
            >
              {loading ? (
                <>
                  <img src={iconLoading} alt="loading" /> Cargando...
                </>
              ) : (
                "Aceptar"
              )}
            </RoundedButton>
          </div>
        </form>
      </div>
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false)
          }}
        >
          {error ? AlertError(message) : AlertSuccess()}
        </Modal>
      )}
    </ChanagePasswordStyled>
  )
}

export default ChangePassword

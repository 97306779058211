import React from "react"
import { MoonLoader   } from "react-spinners"

import { LoadingStyled } from "./LoadingView.style"

const LoadingView = () => {
  const classNameID = `m-loading`

  const override = {
    display: "flex",
    margin: "0 auto",
    borderColor: "#0178ED",
  }

  return (
    <LoadingStyled className={classNameID}>
      <div className={`${classNameID}__container-spinner`}>
        <MoonLoader  
          color={"#0178ED"}
          loading={true}
          cssOverride={override}
          size={80}
          aria-label="Loading Spinner"
          data-testid="loader"
          speedMultiplier={0.5}
        />
      </div>
      <div className={`${classNameID}__container-text`}>Cargando...</div>
    </LoadingStyled>
  )
}

export default LoadingView

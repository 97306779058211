import React from "react"
import { string } from 'prop-types'

import TextTitleStyled from "./TextTitle.style"

const TextTitle = ({ textBlue, textGreen }) => {
  const classNameID = `m-text-title`
  return (
    <TextTitleStyled className={`${classNameID}`}>
      <h1 className={`${classNameID}__primary`}>
        {textBlue} <span>{textGreen}</span>
      </h1>
    </TextTitleStyled>
  )
}

export default TextTitle

TextTitle.propTypes = {
  textBlue: string,
  textGreen: string
}

TextTitle.defaultProps = {
  textBlue: ``,
  textGreen: ``
}

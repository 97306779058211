import styled from 'styled-components'
import { Link } from 'gatsby'

import { device } from '../../../theme/mediaQueries'

export const ContainerButton = styled.div`
    width: max-content;
    display: flex;
    align-items: center;
    cursor: pointer;

    .m-arrow-button__text{
        font-size: 18px;
        font-weight: ${props => props.theme.fonts.fontBold};
        color:  ${props => props.theme.colors.blue};
        margin-left: -8px;
    }

    @media ${device.lg}{
        .m-arrow-button__text{
            font-size: 20px;
        }
    }

    &:hover{
        opacity: 0.7;
    }
`
export const ContainerIcon = styled.div`
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: #E5F1FD;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path{
        stroke: ${props => props.theme.colors.green}
    }
`

export const ButtonLink = styled(Link)`
    width: max-content;
    display: flex;
    align-items: center;
    cursor: pointer;

    .m-arrow-button__text{
        font-size: 18px;
        font-weight: ${props => props.theme.fonts.fontBold};
        color:  ${props => props.theme.colors.blue};
        margin-left: -8px;
    }

    @media ${device.lg}{
        .m-arrow-button__text{
            font-size: 20px;
        }
    }

    &:hover{
        opacity: 0.7;
    }
`
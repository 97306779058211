import React from "react"
import Seo from "../components/seo/Seo"
import Register from "../components/modules/register/Register"

const RegistroPage = () => {
  return <Register />
}

export default RegistroPage

export const Head = () => <Seo title={"Registro"} />
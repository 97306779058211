import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const FooterStyled = styled.footer`
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.colors.footerBgSocial};
  width: 100%;
  z-index: 10;

  .m-footer__container {
    width: 100%;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .m-footer__logo {
    height: 48px;
    margin: 16px 0;
    width: 110px;
  }

  .m-footer__social-media {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;

    .e-footer-icon-link {
      margin-left: 15px;
    }
    .e-footer-icon-link:first-child {
      margin-left: 0;
    }
  }

  .m-footer__social-title {
    color: ${props => props.theme.colors.white};
    display: none;
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 14px;
    font-weight: ${props => props.theme.fonts.fontRegular};
    letter-spacing: 0.5px;
    line-height: 1;
  }

  @media ${device.sm} {
    .m-footer__container {
      padding: 0 42px;
    }
    .m-footer__logo {
      margin: 20px 0;
    }
    .m-footer__social-title {
      display: inline-flex;
      margin-right: 8px
    }
    .m-footer__social-media {
      .e-footer-icon-link {
        margin-left: 16px;
      }
    }
  }

  @media ${device.md} {
    .m-footer__container {
      padding: 0 50px;
    }
    .m-footer__social-title {
      font-size: 16px;
      margin-right: 12px
    }
  }

  @media ${device.lg} {
    .m-footer__container {
      padding: 0 60px;
    }
    .m-footer__logo {
      height: 57px;
      width: 130px;
    }
    .m-footer__social-title {
      margin-right: 25px
    }
    .m-footer__social-media {
      .e-footer-icon-link {
        margin-left: 18px;
      }
    }
  }

  @media ${device.xl} {
    .m-footer__container {
      padding: 0;
      width: 1744px;
      margin: 0 auto;
    }
    .m-footer__logo {
      margin: 18px 0;
    }
    .m-footer__social-title {
      margin-right: 5px
    }
    .m-footer__social-media {
      .e-footer-icon-link {
        margin-left: 20px;
      }
    }
  }
`

export default FooterStyled
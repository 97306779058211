import React from "react"
import Seo from "../components/seo/Seo"
import ChangePassword from "../components/modules/changePassword/ChangePassword"

const CambiarContrasenaPage = () => {
  return <ChangePassword />
}

export default CambiarContrasenaPage

export const Head = () => <Seo title={"Cambiar Contraseña"} />
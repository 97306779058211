import React, { useState, useEffect } from "react"
import { func, bool } from "prop-types"

import { TimerButtonStyle } from "./TimerTextButton.style"

function TimerTextButton({ action, isDisabled }) {
  const classNameID = "timer-text-button"
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [timer, setTimer] = useState(120)
  const [buttonLabel, setButtonLabel] = useState("Reenviar código en 02:00")

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer === 0) {
        setIsButtonEnabled(true)
        setButtonLabel("Reenviar código")
        clearInterval(countdown)
      } else {
        setTimer(timer - 1)
        setButtonLabel(`Reenviar código en ${formatTimer(timer - 1)}`)
      }
    }, 1000)
    return () => clearInterval(countdown)
  }, [timer])

  const formatTimer = timer => {
    const minutes = Math.floor(timer / 60)
    const seconds = timer % 60
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${formattedMinutes}:${formattedSeconds}`
  }

  const sendCodeVerification = async () => {
    try {
      setIsButtonEnabled(false)
      await action()
      setButtonLabel("Código de verificación enviado")
      await new Promise(resolve => setTimeout(resolve, 10000))
      setButtonLabel("Reenviar código en 02:00")
      setTimer(120)
    } catch (e) {
      setButtonLabel("Error al enviar código")
    }
  }

  return (
    <TimerButtonStyle className={classNameID}>
      <button
        className={`${classNameID}__btn ${!isButtonEnabled ? "is-disabled" : ""} ${isDisabled ? "is-disabled" : ""}`}
        disabled={!isButtonEnabled || isDisabled}
        onClick={sendCodeVerification}
      >
        {buttonLabel}
      </button>
    </TimerButtonStyle>
  )
}

TimerTextButton.propTypes = {
  action: func,
  isDisabled: bool,
}

TimerTextButton.defaultProps = {
  action: () => { },
  isDisabled: false,
}

export default TimerTextButton

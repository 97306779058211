import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

export const PasswordStyled = styled.div`
  margin-top: 50px;
  padding: 0  16px;

  .m-recover-password__container-description {
    margin-top: 20px;
    text-align: center;
    
    p {
      font-size: 18px;
      font-weight: ${({ theme }) => theme.fonts.fontRegular};
      color: ${({ theme }) => theme.colors.blueDark};
      line-height: 1.5;
    }
  }

  .m-recover-password__container-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 130px;

    .m-recover-password__formulario{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .m-recover-password__input{
        width: 100%;
        max-width: 380px;
      }

      .m-recover-password__container-button{
        width: 100%;
        text-align: center;
        margin-top: 40px;

        .m-recover-password__button--send{
          width: 258px;
          margin-bottom: 25px;
        }
      }
    }
  }

  @media ${device.sm} {
    margin-top: 130px;

    .m-recover-password__container-form {
      max-width: 780px;
      margin: 80px auto 140px;

      .m-recover-password__formulario {
        flex-direction: column;
        align-items: center;
        .m-recover-password__input{
          width: 370px;
        }

        .m-recover-password__container-button  .m-recover-password__button--send{
          width: 290px;
        }
      }
    }
  }

  @media ${device.md} {
    margin-top: 115px;

    .m-recover-password__container-form {
      width: 815px;
      max-width: 850px;
    }
  }

  @media ${device.lg} {
    .m-recover-password__container-description {
      margin-top: 30px;
      
      p {
        font-size: 20px;
      }
    }

    .m-recover-password__container-form {
      width: 100%;
      max-width: 1035px;
    }
  }

  @media ${device.xl} {
    .m-recover-password__container-form {
      max-width: 1138px;

      .m-recover-password__formulario {
        .m-recover-password__input{
          width: 430px;
          max-width: 430px;
        }
      }
    }
  }
`
import React, { useState, useEffect } from "react"
import { string, bool, number, func } from "prop-types"

import { StyledInputContainer, StyledInputLabel, StyledInputText, StyledInputMask, StyledInputWrapper, StyledInputError } from "./InputText.style"
import iconWarnign from "../../../images/warning_icon.png"
import SvgOpenEye from "../svgs/SvgOpenEye"
import SvgCloseEye from "../svgs/SvgCloseEye"

const classNameID = `m-input`

const InputText = ({ id, name, className, handleInputChange, placeholder, maxLength, disabled, required, error, isOpenModal, type, value, mask }) => {
  const [active, setActive] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const handleOnBlur = e => {
    setActive(false)
    const currentLabel = e.target.value
    if (currentLabel !== "") {
      setActive(true)
    }
  }

  useEffect(()=>{
    if(!isOpenModal){
      setActive(false)
    }
  }, [isOpenModal])
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if(value !== ""){
      setActive(true)
    }
  }, [value])

  return (
    <StyledInputContainer className={`${classNameID} ${className}`}>
      <StyledInputLabel htmlFor="" className={error ? "input-error--label" : ""} $active={active ? "true" : "false"} >
        {placeholder}
      </StyledInputLabel>
      <StyledInputWrapper>
        {             
          !mask ?
            <StyledInputText
              autoComplete="true"
              id={id}
              defaultValue={value}
              name={name}
              maxLength={maxLength}
              onFocus={() => setActive(true)}
              onBlur={handleOnBlur}
              className={error ? "input-error" : ""}
              onChange={handleInputChange}
              required={required}
              disabled={disabled}
              type={type === "password" ? (showPassword ? "text" : "password") : type}
            />
            : 
            <StyledInputMask
              id={id}
              defaultValue={value}
              name={name}
              onFocus={() => setActive(true)}
              onBlur={handleOnBlur}
              className={error ? "input-error" : ""}
              onChange={handleInputChange}
              required={required}
              disabled={disabled}
              mask={mask}
            />
        }
        {type === "password" && !error && (
          <div className={`${classNameID}__password-icon`} >
            <button type="button" onClick={togglePasswordVisibility}>
              {showPassword ? <SvgCloseEye /> :  <SvgOpenEye />}
            </button>
          </div>
        
            )}
        {
          error && (
          <div className={`${classNameID}__warning-icon`}>
            <img src={iconWarnign} alt="warnign" />
          </div>
        )
        }
      </StyledInputWrapper>
      {error && <StyledInputError>{error}</StyledInputError>}
    </StyledInputContainer>
  )
}

InputText.propTypes = {
  placeholder: string,
  name: string,
  id: string,
  className: string,
  required: bool,
  maxLength: number,
  disabled: bool,
  error: string,
  handleInputChange: func,
  isTextArea: bool,
  isOpenModal: bool,
  type: string,
  value: string,
  mask: string
}

InputText.defaultProps = {
  placeholder: "",
  name: "",
  id: "",
  className: "",
  required: false,
  disabled: false,
  error: "",
  maxLength: 50,
  handleInputChange: () => null,
  isTextArea: false,
  isOpenModal: false,
  type: "text", 
  value: "",
  mask: ""
}

export default InputText

import React from "react"
import Seo from "../components/seo/Seo"
import NotFound from "../components/modules/404/NotFound"

const NotFoundPage = () => {
  return <NotFound />
}

export default NotFoundPage

export const Head = () => <Seo title="404: Not Found" />;

import styled from "styled-components";
import { device } from '../../../theme/mediaQueries';

export const RegisterStyled = styled.div`
  margin-top: 50px;
  padding: 0  16px;

  .m-register__container-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 130px;

    .m-register__formulario{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .m-register__input{
        width: 100%;
        max-width: 380px;
      }

      .m-register__checkbox {
        margin-top: 15px;

        a {
          text-decoration: underline;
          color: ${({ theme }) => theme.colors.blue};
        }
      }

      .m-register__container-password-hint {
        margin-top: 20px;
        text-align: center; 
        max-width: 380px;
         
        p {
          font-size: 14px;
        }
      }

      .m-register__container-button{
        width: 100%;
        text-align: center;
        margin-top: 40px;

        .m-register__button--send{
          width: 258px;
          margin-bottom: 25px;
        }
      }
    }
  }

  @media ${device.sm} {
    margin-top: 130px;

    .m-register__container-form {
      max-width: 780px;
      margin: 80px auto 140px;

      .m-register__formulario {
        flex-direction: column;
        align-items: center;
        .m-register__input{
          width: 370px;
        }

        .m-register__checkbox {
          margin-top: 20px;
        }

        .m-register__container-button  .m-register__button--send{
          width: 290px;
        }
      }
    }
  }

  @media ${device.md} {
    margin-top: 115px;

    .m-register__container-form {
      width: 815px;
      max-width: 850px;
    }
  }

  @media ${device.lg} { 
    .m-register__container-form {
      width: 100%;
      max-width: 1035px;
    }

  }

  @media ${device.xl} {
    .m-register__container-form {
      max-width: 1138px;

      .m-register__formulario {
        .m-register__input{
          width: 430px;
          max-width: 430px;
        }

        .m-register__container-password-hint {
          max-width: 430px;
        }
      }
    }
  }
`

import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const HistorialStyle = styled.div`
  margin-top: 20px;
  padding: 0 16px;
  position: relative;
  margin-bottom: 100px;

  .m-history__button-back{
    margin-bottom: 30px;
  }

  .m-history__container_select {
    max-width: 380px;
    margin: 60px auto 0 auto;
  }

  @media ${device.sm} {
    padding: 0 42px;
    margin-bottom: 130px;

    .m-history__container_select {
      max-width: 370px;
      margin: 60px auto 0 auto;
      max-height: 70px;
    }
  }

  @media ${device.md} {
    margin-top: 30px;
    padding: 0 50px;

    .m-history__button-back{
      margin-bottom: 0px;
    } 
  }

  @media ${device.lg} {
    margin-top: 70px;
    padding: 0 60px;

    .m-history__container_select {
      margin: 70px auto 0 auto;
    }
  }

  @media ${device.xl} {
    width: 1744px;
    padding: 0;
    margin: 70px auto 150px;

    .m-history__container_select {
      max-width: 430px;
      margin: 80px auto 0 auto;
    }
  }
`
export default HistorialStyle;

import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

export const ContractEmptyStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: 60vh;
  width: 100%;

  .m-contract-empty, .m-text-title {
    margin: 30px 0;
  }

  p {
    font-size: 18px;
    line-height: 1.5; 
    margin-bottom: 40px;
    max-width: 500px;
    text-align: center;
  }

  @media ${device.sm} {
    max-width: 600px;
  }

  @media ${device.md} {
    max-width: 800px;
  }

  @media ${device.lg} {
    .m-contract-empty, .m-text-title {
      margin: 40px 0;
    }
  }

  @media ${device.xl} {
    
  }
`

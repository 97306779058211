import React from "react"
import Seo from "../components/seo/Seo"
import History from "../components/modules/history/History"

const HistorialPage = () => {
  return <History />
}

export default HistorialPage

export const Head = () => <Seo title={'Historial'} />;
import styled from 'styled-components'
import { device } from '../../../theme/mediaQueries'
import { Link } from "gatsby"

export const RoundedButtonStyled = styled.button`
  align-items: center;
  border: none;
  background-color: ${props => props.theme.colors.pink};
  border-radius: 20px 0;
  color: ${props => props.theme.colors.white};
  display: inline-flex;
  font-size: 18px;
  font-family: ${props => props.theme.fonts.jeko};
  font-weight: ${props => props.theme.fonts.fontSemiBold};
  justify-content: center;
  line-height: 1.2em;
  min-width: 130px;
  outline: none;
  letter-spacing: 1px;
  padding: 18px 35px;
  text-align: center;
  height: 60px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.is-only-border {
    background: transparent !important;
    border: 2px solid rgba(255, 255, 255, 0.6);
    font-weight: ${props => props.theme.fonts.fontBold};
    padding: 16px 35px;
  }
  &.is-blue {
    background-color: ${props => props.theme.colors.blue};
  }

  &.is-transparent {
    background-color: transparent;
    border: 1px solid ${props => props.theme.colors.white};
  }

  img,
  svg {
    width: 20px;
    margin-right: 10px;
    animation-duration: 2s;
    animation-name: slidein;
    animation-iteration-count: infinite;
  }

  @media ${device.sm} {
    min-width: 140px;
  }

  @media ${device.xl} {
    font-size: 18px;
    min-width: 195px;
    padding: 20px 35px;

    &.is-only-border {
      padding: 18px 35px;
    }
  }

  @keyframes slidein {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`

export const LinkButtonStyled = styled(RoundedButtonStyled).attrs({ as: Link })`
  padding: 0 35px;
`

import React, { useState } from "react"
import { StyleCheckbox, InputCheckbox, StyledInputError } from "./Checkbox.style"

const Checkbox = ({ children, handleCheckbox = () => null, error = "", className = "" }) => {
  const [itemActive, setIsActive] = useState(false)
  const classNameID = `e-checkbox`

  function handleClickItem(value) {
    handleCheckbox(value)
    setIsActive(value)
  }

  return (
    <StyleCheckbox className={`${classNameID} ${className}`}>
      <InputCheckbox
        className={`${classNameID}__item ${itemActive ? "is-active" : ""} ${error && !itemActive ? "input-error" : ""}`}
        onClick={() => handleClickItem(!itemActive)}
      >
        <div>{children && <>{children}</>}</div>
      </InputCheckbox>
      {error && !itemActive && <StyledInputError>{error}</StyledInputError>}
    </StyleCheckbox>
  )
}

export default Checkbox

import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

export const LoadingStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;
  width: 100%;

  .m-loading__container-spinner {
    align-items: center;
    display: flex;
    height: 150px;
    width: 150px;
  }

  @media ${device.sm} {
    
  }

  @media ${device.md} {
   
  }

  @media ${device.lg} {
   
  }

  @media ${device.xl} {
    
  }
`

import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const PaySuccessfulStyled = styled.div`
  margin-top: 50px;
  margin-bottom: 130px;
  .m-pay-successful__button-back{
    margin-bottom: 30px;
  }

  .m-pay-successful__container_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .m-pay-successful__button {
      margin-bottom: 20px;
    }
  }

  .m-pay-successful__summary {
    margin: 60px 0px;
    width: 100%;
   
    border: solid 2px #e0f0fe;
    border-radius: 10px;

    .m-pay-successful__summary-row {
      display: flex;
      justify-content: space-between;
      padding: 12px 20px;
      border-bottom: solid 2px #e0f0fe;

      .m-pay-successful__summary-name {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.blueDark};
      }

      .m-pay-successful__summary-value {
        font-size: 22px;
        font-weight: ${({ theme }) => theme.fonts.fontBold};
        color: ${({ theme }) => theme.colors.blue};
        text-align: right;
        text-transform: capitalize;

        &.is-pink {
          color: ${({ theme }) => theme.colors.pink};
          margin-left: 5px;
        }
      } 
    }
    .m-pay-successful__summary-row:last-child {
      border-bottom: none;
    }
  }

  @media ${device.sm} {
    margin-top: 70px;
    .m-pay-successful__summary {
      width: 500px;
      margin: 60px auto;
    }
  }

  @media ${device.md} {
    .m-pay-successful__summary {
      margin: 90px auto;
    }
    .m-pay-successful__button-back{
      margin-bottom: 0px;
    }
  }

  @media ${device.lg} {

  }

  @media ${device.xl} {
   
  }
`
export default PaySuccessfulStyled;

import React from "react"

import TextTitle from "../blocks/title/TextTitle"
import SvgAlert from "../../elements/svgs/SvgAlert"
import RoundedButton from "../../elements/buttons/RoundedButton"
import { ContractEmptyStyled } from "./ViewContractEmpty.style"

const ViewContractEmpty = ( ) => {
  const classNameID = `m-contract-empty`

  return (
    <ContractEmptyStyled className={classNameID}>
      <SvgAlert />
      <TextTitle textBlue="Aún no cuentas" textGreen="con un contrato" />
      <p>Puedes agregar un nuevo contrato con tu número de cuenta y tu número de medidor </p>
       <RoundedButton className={`${classNameID}__button is-blue`} to="/contratos" >
        Agregar contrato
      </RoundedButton>
    </ContractEmptyStyled>
  )
}

ViewContractEmpty.propTypes = {
  
}

ViewContractEmpty.defaultProps = {
  
}

export default ViewContractEmpty

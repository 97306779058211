import React, { useContext, useState } from "react"
import { func } from "prop-types"
import SiteContext from "../../../context/site.context"

import RoundedButton from "../../elements/buttons/RoundedButton"
import UnderlineButton from "../../elements/buttons/UnderlineButton"

import validate from "../../../helpers/formValidationRules"
import useForm from "../../../hooks/useForm"

import { AlertContent, StyleAlertText } from "./EditAccount.style"
import InputText from "../../elements/inputs/InputText"
import CustomAlert from "../../elements/alerts/CustomAlert"

const EditAccount = ({ onClose }) => {
  const classNameID = "m-alert-content"
  const { currentIndex, accounts, loading, updateAliasAccount } =
    useContext(SiteContext)

  const data = { alias: "" }
  const [error, setError] = useState("")
  const [ currentAlias ] = useState(accounts[currentIndex].label)

  const { inputs, handleInputChange, handleSubmit, errors } = useForm(
    data,
    formValidCallback,
    validate
  )

  async function formValidCallback(errorsForm) {
    if (Object.keys(errorsForm).length > 0) {
      return
    }
    const response = await updateAliasAccount(
      accounts[currentIndex].id,
      inputs.alias
    )
    if (response.data) {
      onClose()
    } else {
      setError(response)
    }
  }

  return (
    <>
      {error !== "" ? (
        <CustomAlert
          type="error"
          title="Ocurrió un error"
          description={error}
          onClose={onClose}
          buttonTitle="Aceptar"
          isError={true}
        />
      ) : (
        <AlertContent className={classNameID}>
          <StyleAlertText>
            <h1 className={`${classNameID}__title`}>
              ¿Deseas editar el alias de este contrato?
            </h1>
          </StyleAlertText>
          <form onSubmit={handleSubmit}>
            <InputText
              id="alias"
              name="alias"
              value={currentAlias}
              required
              error={errors.alias}
              handleInputChange={handleInputChange}
              type="text"
              className={`${classNameID}__input`}
              placeholder="Nuevo Alias"
            />
          </form>
          <div className={`${classNameID}__alert-buttons`}>
            <RoundedButton
              className={`${classNameID}__button is-blue`}
              onClick={handleSubmit}
              isDisabled={loading}
            >
              {loading ? "Cargando..." : "Guardar"}
            </RoundedButton>
            <UnderlineButton
              className={`${classNameID}__button-cancel`}
              onClick={onClose}
            >
              Cancelar
            </UnderlineButton>
          </div>
        </AlertContent>
      )}
    </>
  )
}

EditAccount.propTypes = {
  onClose: func,
}

EditAccount.defaultProps = {
  onClose: () => {},
}

export default EditAccount

import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

export const LoginStyled = styled.div`
  margin-top: 50px;

  @media ${device.sm} {
    margin-top: 130px;
  }

  @media ${device.md} {
    margin-top: 115px;
  }

  @media ${device.lg} {
   
  }

  @media ${device.xl} {
   
  }
`

export const StyledFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 130px;
  padding: 0 16px;

  .m-login__formulario{
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;

    .m-login__input{
      width: 100%;
      max-width: 380px;
    }

    .m-login__container-button{
      width: 100%;
      text-align: center;
      margin-top: 70px;

      .m-login__button--send{
        width: 258px;
        margin-bottom: 25px;

      }
    }

    .m-login__container-last-row {
      position: relative;
      width: 380px;

      .m-login__container-button-text {
        position: absolute;
        bottom: -10px;
        right: 0;

        a {
          background: transparent;
          border: none;
          color: ${props => props.theme.colors.blueDark};
          font-size: 14px;
          font-weight: ${props => props.theme.fonts.fontSemiBold};
          font-family: ${props => props.theme.fonts.jeko};

          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }

  }

  @media ${device.sm}{
    max-width: 780px;
    margin: 80px auto 140px;

    .m-login__formulario{
      flex-direction: column;
      align-items: center;
      .m-login__input{
        width: 370px;
      }

      .m-login__container-button  .m-login__button--send{
        width: 290px;
      }

      .m-login__container-last-row {
        width: auto;
      }
    }
  }

  @media ${device.md}{
    width: 815px;
    max-width: 850px;
  }

  @media ${device.lg}{
    width: 100%;
    max-width: 1035px;
  }

  @media ${device.xl}{
    max-width: 1138px;

    .m-login__formulario{
      .m-login__input{
          width: 430px;
          max-width: 430px;
      }
    }
  }
`

export const StyleTextError = styled.div`
  text-align: center;
  width: 250px;
  margin: 0 auto;

  @media ${device.sm}{
    width: 100%
  }
  p{
    color: ${props => props.theme.colors.pink};
    font-size: 12px;
    font-weight: ${props => props.theme.fonts.fontBold};

    @media ${device.sm }{
      width: 300px;
      margin: 0 auto;
    }
    @media ${device.lg}{
      font-size: 13px;
      width: 280px;
    }
  }
`

import React, { useState, useContext, useEffect } from "react"
import { Link, navigate } from "gatsby"
import SiteContext from "../../../context/site.context"

import { resetPassword } from "../../../services/accounts"

import validate from "../../../helpers/formValidationRules"
import useForm from "../../../hooks/useForm"

import TextTitle from "../blocks/title/TextTitle"
import InputText from "../../elements/inputs/InputText"
import RoundedButton from "../../elements/buttons/RoundedButton"
import UnderlineButton from "../../elements/buttons/UnderlineButton"
import Modal from "../modal/Modal"
import CustomAlert from "../../elements/alerts/CustomAlert"

import iconLoading from "../../../images/loading.png"

import { RestorePasswordStyled } from "./RestorePassword.style"
const RestorePassword = () => {
  const classNameID = `m-restore-password`
  const [showModal, setShowModal] = useState(false)
  const [token, setToken] = useState("")
  const [error, setError] = useState(false)

  const { loading, setloading } = useContext(SiteContext)

  const data = {
    password: "",
    confirmPassword: "",
  }

  const { inputs, handleInputChange, handleSubmit, errors } = useForm(
    data,
    formValidCallback,
    validate
  )

  async function formValidCallback(errorsForm) {
    if (Object.keys(errorsForm).length > 0) {
      return
    }
    setloading(true)
    try {
      await resetPassword(token, inputs.password);
      setShowModal(true)
      setloading(false)
    } catch (error) { 
      setError(true)
      setloading(false)
      setShowModal(true)
    }
  }

  useEffect(() => { 

    function getUrlParameter(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(window.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    const tokenValue = getUrlParameter('b');
    setToken(tokenValue);
  }, [])

  return (
    <RestorePasswordStyled className={classNameID}>
      <TextTitle textBlue="Restablecer" textGreen="contraseña" />
      <div className={`${classNameID}__container-form`}>
        <form
          id="form-restore-password"
          noValidate
          className={`${classNameID}__formulario`}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <InputText
            id="password"
            name="password"
            className={`${classNameID}__input`}
            type="password"
            placeholder="*Contraseña"
            handleInputChange={handleInputChange}
            required
            error={errors.password}
          />
          <InputText
            id="confirmPassword"
            name="confirmPassword"
            className={`${classNameID}__input`}
            type="password"
            placeholder="*Confirmar contraseña"
            handleInputChange={handleInputChange}
            required
            error={errors.confirmPassword}
          />
           <div className={`${classNameID}__container-password-hint`} >
            <p>
              La contraseña debe tener al menos 8 caracteres, incluyendo
              mayúsculas, números y caracteres especiales.
            </p>
          </div>
          <div className={`${classNameID}__container-button`}>
            <RoundedButton
              className={`${classNameID}__button--send is-blue has-animation`}
              type="submit"
              onClick={handleSubmit}
              isDisabled={loading}
            >
              {loading ? (
                <>
                  <img src={iconLoading} alt="loading" /> Enviando...
                </>
              ) : (
                "Enviar"
              )}
            </RoundedButton>
          </div>
        </form>
        <Link to="/login">
          <UnderlineButton className={`${classNameID}__button--cancelar`}>
            Cancelar
          </UnderlineButton>
        </Link>
      </div>
      {showModal && (
        <Modal onClose={() => {setShowModal(false)}}>
          {
            <CustomAlert
              onClose={() => {
                setShowModal(false)
                !error && navigate("/login")
              }}
              title={error ? "Error" : "¡Listo!"}
              description={error ? "Ocurrio un error, intentalo nuevamente" : "Tu contraseña ha sido restablecida exitosamente."}
              buttonTitle="Aceptar"
              isError={error}
            />
          }
        </Modal>
      )}
    </RestorePasswordStyled>
  )
}

export default RestorePassword

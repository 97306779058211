import * as React from "react"

function SvgOpenEye() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={12} fill="none">
    <g fill="#0178ED" clipPath="url(#a)">
      <path d="m17.75 6.606-1.93 1.928c-3.766 3.764-9.873 3.764-13.64 0L.251 6.606a.856.856 0 0 1 0-1.211l1.93-1.928c3.766-3.765 9.873-3.765 13.64 0l1.928 1.928a.856.856 0 0 1 0 1.21ZM8.941 2.162a3.83 3.83 0 0 0-3.754 4.573 3.826 3.826 0 0 0 5.22 2.789 3.828 3.828 0 0 0 2.363-3.535 3.826 3.826 0 0 0-3.829-3.827Z" />
      <path d="M8.942 3.97a2.018 2.018 0 1 1 .001 4.037 2.018 2.018 0 0 1 0-4.036Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 11.357h18V.643H0z" />
      </clipPath>
    </defs>
  </svg>
  )
}

export default SvgOpenEye

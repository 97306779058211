import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

export const PayOnLineStyled = styled.div`
  margin-top: 20px;
  padding: 0 16px;
  position: relative;

  @media ${device.sm} {
    padding: 0 42px;
  }

  @media ${device.md} {
    margin-top: 30px;
    padding: 0 50px;
  }

  @media ${device.lg} {
    margin-top: 70px;
    padding: 0 60px;
  }

  @media ${device.xl} {
    width: 1744px;
    padding: 0;
    margin: 70px auto 0;
  }
`
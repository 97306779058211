import React, { useContext, useEffect } from "react"
import SiteContext from "../../../context/site.context"
import { StaticImage } from "gatsby-plugin-image"
import { isLoggedIn } from "../../../services/auth"

import RoundedButton from "../../elements/buttons/RoundedButton"

import HomeStyled from "./Home.style"

const Home = () => {
  const classNameID = `m-home`
  const { setHeaderWhite } = useContext(SiteContext)
  const isLogged = isLoggedIn()

  useEffect(() => {
    setHeaderWhite(true)

    return () => {
      setHeaderWhite(false)
    }
  }, [])

  return (
    <HomeStyled id="home" className={classNameID}>
      <div className={`${classNameID}__background`} />
      <div className={`${classNameID}__container-content`}>
        <div className={`${classNameID}__container-image`}>
          <StaticImage
            src="../../../images/cover-oficina-virtual-agsal.png"
            alt="background-image"
            placeholder="blurred"
            className={`${classNameID}__image`}
          />
        </div>
        <div className={`${classNameID}__container-left`}>
          <div className={`${classNameID}__container-text`}>
            <h1>¡Bienvenido a nuestra oficina virtual!</h1>
            <p>
              Tu asistente digital para gestionar todos los trámites
              relacionados con tus servicios de agua.
            </p>
          </div>
          <div className={`${classNameID}__container-buttons`}>
            {isLogged ? (
              <RoundedButton to="/cuenta">
                Ir a mi cuenta
              </RoundedButton>
            ) : (
              <>
                <RoundedButton to="/login">Iniciar sesión</RoundedButton>
                <RoundedButton to="/registro" className={"is-transparent"}>
                  Crear cuenta
                </RoundedButton>
              </>
            )}
          </div>
        </div>
      </div>
    </HomeStyled>
  )
}

export default Home

import styled from 'styled-components'
import { device } from '../../../theme/mediaQueries'

const UnderlineButtonStyled = styled.button`
  align-items: center;
  border: none;
  background-color: transparent;
  color: ${props => props.theme.colors.blueDark};
  display: inline-flex;
  font-size: 18px;
  font-family: ${props => props.theme.fonts.jeko};
  font-weight: ${props => props.theme.fonts.fontBold};
  justify-content: center;
  line-height: 1.2em;
  min-width: 130px;
  outline: none;
  letter-spacing: 1px;
  padding: 18px 35px;
  text-align: center;
  position: relative;

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:before {
    content: '';
    background-color: ${props => props.theme.colors.pink};
    width: 50px;
    height: 2px;
    position: absolute;
    bottom: 0;
    animation-name: shrinkLine;
    animation-duration: 0.1s;
    animation-fill-mode: forwards;
  }

  &:hover {
    cursor: pointer;
    
    &:before {
      animation-name: expandLine;
      animation-duration: 0.25s;
      animation-fill-mode: forwards;
    }
  }

  @media ${device.sm} {
    min-width: 140px;
  }

  @media ${device.xl} {
    font-size: 18px;
    padding: 20px 35px;
  }

  @keyframes expandLine {
    0% { width: 50px; }
    100% { width: calc(100% - 70px); }
  }

  @keyframes shrinkLine {
    0% { width: calc(100% - 70px);
    }
    100% { width: 50px; }
  }

`

export default UnderlineButtonStyled
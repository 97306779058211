import React, { useContext, useRef } from "react"
import ReactToPrint from "react-to-print"
import SiteContext from "../../../context/site.context"
import { formatDate } from "../../../hooks/useFormatStrings"

import TextTitle from "../blocks/title/TextTitle"
import RoundedButton from "../../elements/buttons/RoundedButton"
import SvgLogoWhite from "../../elements/svgs/SvgLogoWhite"

import {PayVoucherStyled, ButtonPrintStyle} from "./PaymentVoucher.style"

const PaymentVoucher = () => {
  const classNameID = `m-pay-voucher`
  const { successDetailsPayment } = useContext(SiteContext)
  const componentToPrintRef = useRef()

  const createRow = (label, value, className) => {
    return (
      <div className={`${classNameID}__summary-row`}>
        <p className={`${classNameID}__summary-name`}>{label}</p>
        <p className={`${classNameID}__summary-value ${className}`}>{value}</p>
      </div>
    )
  }

  const getSummary = () => {
    return (
      <div className={`${classNameID}__summary`}>
        {createRow("Pago:", `$${successDetailsPayment.amount}`, "is-pink")}
        {createRow("No. tarjeta :", `${successDetailsPayment.card}`, "")}
        {createRow("Fecha:", `${formatDate(successDetailsPayment.date)}`, "")}
        {createRow("Referencia:", `${successDetailsPayment.reference}`, "")}
        {createRow("No. autorización:", `${successDetailsPayment.authorizationCode}`, "")}
        {successDetailsPayment.reconnectionOrder !== null &&
          createRow("Orden de reconexión:", `${successDetailsPayment.reconnectionOrder}`, "")
        }
      </div>
    )
  }

  const documentToPrint = () => {
    return (
      <PayVoucherStyled className={classNameID}>
        <SvgLogoWhite className={`${classNameID}__logo-agsal`} />
        <TextTitle textBlue="Comprobante de" textGreen="pago" />
        {getSummary()}
      </PayVoucherStyled>
    )
  }

  return (
    <ButtonPrintStyle>
      <ReactToPrint
        documentTitle="Comprobante de pago AGSAL"
        trigger={() => (
          <RoundedButton className={`${classNameID}__button is-blue`}>
            Comprobante
          </RoundedButton>
        )}
        content={() => componentToPrintRef.current}
      />
      <div className="print-voucher--hidden" ref={componentToPrintRef}>
        {documentToPrint()}
      </div>
    </ButtonPrintStyle>
  )
}

export default PaymentVoucher

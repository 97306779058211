import React, { useEffect, useContext, useState } from "react"
import PropTypes from "prop-types"

import SiteContext from "../../../../context/site.context"
import { getMonths, getStatus } from "../../../../hooks/useFormatStrings"	

import HistoryTableStyle from "./HistoryTable.style"

const HistoryTable = ({ historyTitle, id, history, isSanitation }) => {
  const { loading, getPdfInvoice, accounts, currentIndex, getXmlInvoice } =
    useContext(SiteContext)
  const [disabledButton, setDisabledButton] = useState({
    invoiceNumber: null,
    type: null,
  })
  const [dots, setDots] = useState('');

  const classNameID = `m-history-table`
  const tableTitle = "Historial de facturas y consumos de"

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots.length === 3 ? '' : prevDots + '.'));
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const tableContent = document.getElementById(`${id}__container-table`)
    const progressBar = document.getElementById(`${id}__progress-bar`)

    tableContent.addEventListener("scroll", () => {
      const scrollPercentage =
        (tableContent.scrollLeft /
          (tableContent.scrollWidth - tableContent.clientWidth)) *
        100
      progressBar.style.width = `${scrollPercentage}%`
    })

    return () => {
      tableContent.removeEventListener("scroll", () => {})
    }
  }, [id])

  const createBlob = (data, type, fileName) => { 
    
    const blob = new Blob([data], { type: `application/${type}` })

    // Create a URL for the blob file
    const url = window.URL.createObjectURL(blob)

    // Create a link element to download the file
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${fileName}.${type}`)
    document.body.appendChild(link)
    link.click()

    // Remove the link element
    document.body.removeChild(link)
  }

  const downloadXml = async (year, period,  invoiceNumber) => {
    setDisabledButton({ invoiceNumber, type: 'xml' });
    
    const summaryName = `${getMonths(period)}_${year}`;  
    const accountNumber = accounts[currentIndex].accountNumber
    const response = await getXmlInvoice(invoiceNumber, accountNumber)

    createBlob(response.data, "xml", summaryName)
    setDisabledButton({invoiceNumber: null, type: null});
  }

  const downloadPdf = async (year, period,  invoiceNumber) => {
    setDisabledButton({ invoiceNumber, type: 'pdf' });

    const summaryName = `${getMonths(period)}_${year}`;
    const accountNumber = accounts[currentIndex].accountNumber
    const response = await getPdfInvoice(invoiceNumber, accountNumber)

    createBlob(response.data, "pdf", summaryName)
    setDisabledButton({invoiceNumber: null, type: null});
  }

  const createHeader = () => {
    return (
      <div className={`${classNameID}__table--header`}>
        <div className={`${classNameID}__table--header-item`}>Año</div>
        <div className={`${classNameID}__table--header-item`}>Periodo</div>
        <div className={`${classNameID}__table--header-item`}>Estado</div>
        <div className={`${classNameID}__table--header-item`}> Cons/M<sup>3</sup> </div>
        <div className={`${classNameID}__table--header-item`}>Importe</div>
        <div className={`${classNameID}__table--header-item`}>Acciones</div>
      </div>
    )
  }

  const getTableBody = (id, year, period, status, cons, amount, invoiceNumber) => {
    
    let classNameStatus = ''
    if (status === 2) {
      classNameStatus = 'is-pink'
    }
    if (status === 3) {
      classNameStatus = 'is-blue'
    }

    return (
      <div
        key={`table-history__${id}`}
        className={`${classNameID}__table--body`}
      >
        <div className={`${classNameID}__table--row`}>
          <div className={`${classNameID}__table--row-item`}>{year}</div>
          <div className={`${classNameID}__table--row-item`}>{period}</div>
          <div className={`${classNameID}__table--row-item ${classNameStatus} `}>
            {getStatus(status)}
          </div>
          <div className={`${classNameID}__table--row-item`}>{cons}</div>
          <div className={`${classNameID}__table--row-item`}>${amount}</div>
          <div className={`${classNameID}__table--row-item`}>
            <button
              disabled={
                isSanitation ||
                (disabledButton.invoiceNumber === invoiceNumber &&
                disabledButton.type === "xml")
              }
              className={`${
                isSanitation ||
                (disabledButton.invoiceNumber === invoiceNumber &&
                disabledButton.type === "xml")
                  ? "is-disabled"
                  : ""
              }`}
              onClick={() => { downloadXml(year, period, invoiceNumber) }}
            >
             {
                disabledButton.invoiceNumber === invoiceNumber &&
                disabledButton.type === "xml" ? dots : 'XML'
              }
            </button>{" "}
            |{" "}
            <button
              disabled={
                disabledButton.invoiceNumber === invoiceNumber &&
                disabledButton.type === "pdf"
              }
              className={`${
                disabledButton.invoiceNumber === invoiceNumber &&
                disabledButton.type === "pdf"
                  ? "is-disabled"
                  : ""
              }`}
              onClick={() => {
                downloadPdf(year, period, invoiceNumber)
              }}
            >
              {
                disabledButton.invoiceNumber === invoiceNumber &&
                disabledButton.type === "pdf" ? dots : 'PDF'
              }
            </button>
          </div>
        </div>
      </div>
    )
  }
  
  return (
    <HistoryTableStyle className={`${classNameID}`}>
      <div className={`${classNameID}__container-title`}>
        <p>
          {tableTitle} {historyTitle}
        </p>
      </div>
      <div
        id={`${id}__container-table`}
        className={`${classNameID}__container-table`}
      >
        <div className={`${classNameID}__table`}>
          {createHeader()}
          {loading ? (
            <div className={`${classNameID}__container-loading`}>
              Cargando...
            </div>
          ) : (
            history &&
            history.length > 0 &&
            history.map((item, index) => {
              return getTableBody(
                `${id}__table--body-${index}}`,
                item.year,
                item.period,
                item.status,
                item.consumption,
                item.totalAmount,
                item.invoiceNumber
              )
            })
          )}
        </div>
      </div>
      <div
        id={`${id}__scroll-indicador`}
        className={`${classNameID}__scroll-indicator`}
      >
        <div
          id={`${id}__progress-bar`}
          className={`${classNameID}__progress-bar`}
        ></div>
      </div>
    </HistoryTableStyle>
  )
}

HistoryTable.propTypes = {
  historyTitle: PropTypes.string,
  id: PropTypes.string,
  history: PropTypes.array,
  isSanitation: PropTypes.bool,
}

HistoryTable.defaultProps = {
  historyTitle: ``,
  id: ``,
  history: [],
  isSanitation: false,
}

export default HistoryTable

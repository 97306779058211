import React, { useContext, useState } from "react"
import { func, bool } from "prop-types"
import SiteContext from "../../../context/site.context"

import validate from "../../../helpers/formValidationRules"
import useForm from "../../../hooks/useForm"

import TextTitle from "../blocks/title/TextTitle"
import InputText from "../../elements/inputs/InputText"
import RoundedButton from "../../elements/buttons/RoundedButton"
import ButtonArrowLeft from "../../elements/buttons/ButtonArrowLeft"
import Modal from "../modal/Modal"
import CustomAlert from "../../elements/alerts/CustomAlert"

import iconLoading from "../../../images/loading.png"

import AddNewContractStyle from "./AddNewContract.style"

const AddNewContract = ({ handleNextStep, firstContract }) => {
  const classNameID = `m-add-new-contract`
  const { loading, createNewAccount, error } = useContext(SiteContext)
  const [showModal, setShowModal] = useState(false)

  const data = {
    accountNumber: "",
    label: "",
    meterId: "",
  }

  const { inputs, handleInputChange, handleSubmit, errors } = useForm(
    data,
    formValidCallback,
    validate
  )

  async function formValidCallback(errorsForm) {
    if (Object.keys(errorsForm).length > 0) {
      return
    }
    await createNewAccount(inputs)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    handleBack()
  }

  const handleBack = () => { 
    if (firstContract) {
      window.history.back();
    } else {
      handleNextStep(0)
    }
  }

  return (
    <AddNewContractStyle className={classNameID}>
      <ButtonArrowLeft
        onClick={handleBack}
        className={`${classNameID}__button-back`}
      >
        Regresar
      </ButtonArrowLeft>
      <TextTitle textBlue="Agregar" textGreen="contrato" />
      <div className={`${classNameID}__container-form`}>
        <form
          id="form-add-contract"
          noValidate
          className={`${classNameID}__formulario`}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <InputText
            id="label"
            name="label"
            className={`${classNameID}__input`}
            type="text"
            placeholder="*Alias del contrato"
            handleInputChange={handleInputChange}
            required
            error={errors.label}
          />
          <InputText
            id="accountNumber"
            name="accountNumber"
            className={`${classNameID}__input`}
            type="number"
            placeholder="No. contrato"
            handleInputChange={handleInputChange}
            required
            error={errors.accountNumber}
          />
          <InputText
            id="meterId"
            name="meterId"
            className={`${classNameID}__input`}
            type="text"
            placeholder="*No. de medidor"
            handleInputChange={handleInputChange}
            required
            error={errors.meterId}
          />
          <div className={`${classNameID}__container-button`}>
            <RoundedButton
              className={`${classNameID}__button--send is-blue has-animation`}
              type="submit"
              onClick={handleSubmit}
              isDisabled={loading}
            >
              {loading ? (
                <>
                  <img src={iconLoading} alt="loading" /> Guardando...
                </>
              ) : (
                "Agregar"
              )}
            </RoundedButton>
          </div>
        </form>
      </div>
      {showModal && (
        <Modal onClose={() => {setShowModal(false)}}>
          {error !== null ? (
            <CustomAlert
              onClose={() => { setShowModal(false) } }
              title="Error al agregar contrato"
              description={error}
              buttonTitle="Aceptar"
              isError={true}
            />
          ) : (
            <CustomAlert
              onClose={closeModal}
              title="Contrato Agregado"
              description="Tu contrato ha sido agregado correctamente"
              buttonTitle="Aceptar"
            />
          )}
        </Modal>
      )}
    </AddNewContractStyle>
  )
}

AddNewContract.propTypes = {
  handleNextStep: func,
  firstContract: bool,
}

AddNewContract.defaultProps = {
  handleNextStep: () => { },
  firstContract: false,
}

export default AddNewContract

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cambiar-contrasena-jsx": () => import("./../../../src/pages/cambiar-contrasena.jsx" /* webpackChunkName: "component---src-pages-cambiar-contrasena-jsx" */),
  "component---src-pages-contratos-jsx": () => import("./../../../src/pages/contratos.jsx" /* webpackChunkName: "component---src-pages-contratos-jsx" */),
  "component---src-pages-cuenta-jsx": () => import("./../../../src/pages/cuenta.jsx" /* webpackChunkName: "component---src-pages-cuenta-jsx" */),
  "component---src-pages-historial-jsx": () => import("./../../../src/pages/historial.jsx" /* webpackChunkName: "component---src-pages-historial-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-pago-en-linea-jsx": () => import("./../../../src/pages/pago-en-linea.jsx" /* webpackChunkName: "component---src-pages-pago-en-linea-jsx" */),
  "component---src-pages-recuperar-contrasena-jsx": () => import("./../../../src/pages/recuperar-contrasena.jsx" /* webpackChunkName: "component---src-pages-recuperar-contrasena-jsx" */),
  "component---src-pages-registro-jsx": () => import("./../../../src/pages/registro.jsx" /* webpackChunkName: "component---src-pages-registro-jsx" */),
  "component---src-pages-restablecer-contrasena-jsx": () => import("./../../../src/pages/restablecer-contrasena.jsx" /* webpackChunkName: "component---src-pages-restablecer-contrasena-jsx" */)
}


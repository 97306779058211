import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const StepPayStyle = styled.div`

  .m-step-pay__button-back{
    margin-bottom: 30px;
  }

  .m-step-pay__container_button {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 100px;
    text-align: center;

    .m-step-pay__button {
      width: 258px;      
    }
  }

  .m-step-pay__container_select {
    margin: 60px auto 0 auto;
    max-width: 380px;
    width: 100%;
  }

  .m-step-pay__message-amount {
    font-size: 18px;
    margin-top: 30px;
    text-align: center;
    width: 100%;
  }

  @media ${device.sm} {

    .m-step-pay__container_select {
      max-width: 370px;
      margin: 60px auto 0 auto;
      max-height: 70px;
    }

    .m-step-pay__container_button  .m-step-pay__button {
      width: 290px;      
    }
   
  }

  @media ${device.md} {
    .m-step-pay__button-back{
      margin-bottom: 0px;
    } 
  }

  @media ${device.lg} {
    .m-step-pay__container_select {
      margin: 70px auto 0 auto;
    }
  }

  @media ${device.xl} {
    .m-step-pay__container_select {
      max-width: 430px;
      margin: 80px auto 0 auto;
    }
  }
`

export default StepPayStyle;

import styled from 'styled-components';
import { device } from '../../../../theme/mediaQueries'

const HistoryTableStyle = styled.div`
  margin: 60px 0 110px 0;
  position: relative;

  .m-history-table__container-title {
    margin-bottom: 30px;
    p {
      font-size: 20px;
      font-weight: ${({ theme }) => theme.fonts.fontBold};
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  .m-history-table__container-table {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 40px 0px;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .m-history-table__container-table::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .m-history-table__table {
    width: 660px;    
    .m-history-table__table--header {
      background-color: ${({ theme }) => theme.colors.blue};
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .m-history-table__table--header-item {
        width: 100%;
        min-width: 110px;
        padding: 16px 0;
        text-align: center;
        color: ${({ theme }) => theme.colors.white};
        font-size: 16px;
        font-weight: ${({ theme }) => theme.fonts.fontBold};
        border-right: 1px solid ${({ theme }) => theme.colors.white};
      }

      .m-history-table__table--header-item:last-child { 
        border-right: none;
      }
    }

    .m-history-table__container-loading {
      align-items: center;
      display: flex;
      font-size: 18px;
      justify-content: center;
      min-height: 250px;
      width: 100vw;
    }

    .m-history-table__table--body {
      background-color: #f8f8f8;

      .m-history-table__table--row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .m-history-table__table--row-item {
          width: 100%;
          min-width: 110px;
          padding: 16px 0;
          text-align: center;
          font-size: 16px;
          font-weight: ${({ theme }) => theme.fonts.fontMedium};
          color: ${({ theme }) => theme.colors.black};

          &.is-pink {
            color: ${({ theme }) => theme.colors.pink};
          }
          &.is-blue {
            color: ${({ theme }) => theme.colors.blue};
          }

          button {
            background-color: transparent;
            border: none;
            color: ${({ theme }) => theme.colors.blue};
            cursor: pointer;
            font-size: 16px;
            font-weight: ${({ theme }) => theme.fonts.fontBold};
            padding: 0;
            min-width: 30px;

            &.is-disabled {
              color: #000000 !important;
              cursor: auto;
              opacity: 0.5;
            }
          }
          button:last-child {
            color: ${({ theme }) => theme.colors.pink};
          }
        }
      }
    }

    .m-history-table__table--body:nth-child(odd){
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  .m-history-table__scroll-indicator {
    position: absolute;
    bottom: -50px;
    left: calc(50% - 50px);
    width: 100px;
    height: 10px;
    background-color: transparent;
    border-radius: 10px;
    border: solid 1px ${({ theme }) => theme.colors.blue};
    z-index: 1;
  }

  .m-history-table__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    border-radius: 10px;
    width: 0; /* Inicialmente sin ancho */
    background-color: ${({ theme }) => theme.colors.blue}; /* Color de la barra de progreso */
    z-index: 2;
  }


  @media ${device.sm} {
    .m-history-table__container-table {
      overflow-x: none;
      .m-history-table__table {
        width: 100%;
      }
      .m-history-table__container-loading {
        width: 100%;
      }
    }

    .m-history-table__scroll-indicator,
    .m-history-table__progress-bar {
      display: none;
    }
  }

  @media ${device.md} {
    
  }

  @media ${device.lg} {
    width: 1200px;
    margin: 60px auto;
    .m-history-table__container-title {
      p {
        font-size: 26px;
      }
    }
    .m-history-table__table {
      .m-history-table__table--header 
      .m-history-table__table--header-item {
        font-size: 18px;
      }

      .m-history-table__table--body
      .m-history-table__table--row
      .m-history-table__table--row-item {
        font-size: 18px;
      }
    }
  }

  @media ${device.xl} {
    width: 1430px;
    margin: 100px auto;
   
  }
`

export default HistoryTableStyle;

import React, { useEffect, useState } from "react"
import { func, object } from "prop-types"

import SvgAlert from "../../elements/svgs/SvgAlert"
import RoundedButton from "../../elements/buttons/RoundedButton"
import wsErrors from '../../../../static/data/errors.json'

import {
  ModalCard,
  StyleModalImage,
  StyleModalText,
} from "./ContentModalForm.style"


const ContentModal = ({ setShowModal, paymentErrors }) => {
  const classNameID = "m-modal-content"
  const [errorDescription, setErrorDescription] = useState('')

  const setErrorsTypes = (errorType, reference, authorizationCode) => { 
    const errorObject = Object.values(wsErrors).find(e => e.error === errorType);
    var errorMessages = errorObject?.message || wsErrors['unexpected_error'].message

    if (reference !== '') errorMessages = errorMessages.replace('{reference}', reference)
    if (authorizationCode !== '') errorMessages = errorMessages.replace('{authorizationCode}', authorizationCode)
    setErrorDescription(errorMessages)
  }

  useEffect(() => { 
    const errorType = paymentErrors.error;
    const errorMessage = paymentErrors.message

    var regEx = /authorizationCode: (\d+), reference: (\d+)/
    var result = regEx.exec(errorMessage);
    var authorizationCode
    var reference

    if (result) {
      authorizationCode = result[1];
      reference = result[2];
      setErrorsTypes(errorType, reference, authorizationCode) 
    } else {
      setErrorsTypes(errorType, '', '')  
    }
  }, [paymentErrors])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <ModalCard className={classNameID}>
      <StyleModalImage>
        <SvgAlert />
      </StyleModalImage>
      <StyleModalText>
        <h1 className={`${classNameID}__title`}>Hubo un problema al procesar tu pago</h1>
        <p>{ errorDescription }</p>
      </StyleModalText>
      <RoundedButton className={`${classNameID}__button is-blue`} onClick={handleCloseModal} >
        Intentar de nuevo
      </RoundedButton>
    </ModalCard>
  )
}

ContentModal.propTypes = {
  setShowModal: func,
  paymentErrors: object,
}

ContentModal.defaultProps = {
  setShowModal: () => { },
  paymentErrors: {},
}

export default ContentModal

import styled from "styled-components";

export const TimerButtonStyle = styled.div`
  width: 100%;
  padding: 30px 0;

  .timer-text-button__btn {
    align-items: center;
    border: none;
    background-color: transparent;
    color: #244168;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-family: Jeko,"sans-serif";
    font-weight: 700;
    justify-content: center;
    letter-spacing: 1px;
    line-height: 1.2em;
    min-width: 130px;
    outline: none;
    padding: 18px 35px;
    position: relative;
    text-align: center;

    &.is-disabled {
      color: #244168;
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
    }
  }

  .timer-text-button__btn::before {
    background-color: ${props => props.theme.colors.pink};
    border-radius: 50%;
    bottom: 0;
    content: "";
    height: 2px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: width 0.3s ease-in-out;
    width: 50px;
  }

  .timer-text-button__btn:hover::before {
    width: calc(100% - 70px);
  }
`

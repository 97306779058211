import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const HomeStyled = styled.div`
  position: relative;
    .m-home__background {
      background-color: ${({ theme }) => theme.colors.blue};
      position: absolute;
      top: -72px;
      min-height: calc(100% + 152px);
      width: 100%;
      z-index: -1;
    }

    .m-home__container-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      max-width: 450px;
      margin: 0 auto;
      padding: 100px 30px;

    }

    .m-home__container-image {
      aspect-ratio: 658 / 473;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .m-home__container-left {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    .m-home__container-text {
      margin-top: 40px;
      text-align: center;
      h1 {
        color: ${({ theme }) => theme.colors.white};
        font-size: 36px;
        font-weight: ${({ theme }) => theme.fonts.fontExtraBold};
        line-height: 1.2;
      }
      p {
        color: ${({ theme }) => theme.colors.white};
        font-size: 16px;
        line-height: 1.5;
        margin-top: 20px;
      }
    }

    .m-home__container-buttons {
      margin: 50px 0;
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 280px;
    }

  @media ${device.sm} {
    .m-home__background {
      top: -150px;
      min-height: calc(100vh + 500px)
    }
    .m-home__container-content {
      max-width: 600px;
      padding: 100px 50px;
    }
    .m-home__container-text {
      h1 {
        font-size: 44px;
      }    
    }
  }

  @media ${device.md} {
    .m-home__container-content {
      flex-direction: row-reverse;
      max-width: 100%;
      padding: 100px 0;
    }

    .m-home__container-left {
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0 50px 0 100px;
      width: 550px;
    }
    .m-home__container-text {
      margin-top: 0;
      text-align: left;
    }
  }

  @media ${device.lg} {
    .m-home__container-content {
      padding: 120px 0;
      width: 1000px;
    }
    .m-home__container-left {
      margin-left: 0px;
      width: 700px;
    }
    .m-home__container-text {
      h1 {
        font-size: 56px;
      } 
      p {
        font-size: 18px;
        margin-top: 40px; 
      }
    }
    .m-home__container-buttons {
      flex-direction: row;
      width: 100%;
    }
    
  }

  @media ${device.xl} {
    .m-home__container-content {
      width: 1400px;
    }

    .m-home__container-text {
      h1 {
        font-size: 82px;
      } 
      p {
        font-size: 20px;
        margin-top: 50px; 
        max-width: 500px;
      }
    }
    .m-home__container-buttons {
      gap: 50px;
    }
  }
`;


export default HomeStyled;

import axios from 'axios';
import apiWebServices from './api-web-services';

const baseUrl = process.env.GATSBY_BASE_URL

export const getAccounts = async () => {
  try {
    const response = await apiWebServices.get(`${baseUrl}/api/v1/user/accounts`);
    const { data } = response;
    return data; 
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const updateAccount = async (id, label) => {
  try {
    const response = await apiWebServices.put(`${baseUrl}/api/v1/user/accounts/${id}`,
      { label },
    );
    const { data } = response;
    return data; 
  } catch (error) {
    throw error;
  }
};

export const createAccount = async (accountNumber, label, meterId) => {
  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/user/accounts`,
      { 'account': {accountNumber, label, meterId} },
    );
    const { data } = response;
    return data; 
  } catch (error) {
    throw error
  }
};

export const updatePassword = async (password, newPassword) => {
  try {
    const response = await apiWebServices.put(`${baseUrl}/api/v1/user/`,
      { password, newPassword },
    );
    const { data } = response;
    return data; 
  } catch (error) {
    throw error
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${baseUrl}/api/v1/auth/forgot-password`,
      { email },
    );
    return response; 
  } catch (error) {
    throw error
  }
};

export const resetPassword = async (token, password) => { 
  try {
    const response = await axios.post(`${baseUrl}/api/v1/auth/reset-password`,
      { newPassword: password },
      { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${atob(token)}` } },
    );

    return response; 
  } catch (error) {
    throw new Error(error);
  }
}

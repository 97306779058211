import React, { useContext, useState } from "react"
import { func, array } from "prop-types"
import SiteContext from "../../../context/site.context"

import { createPaymentIntent, confirmPaymentIntent, checkBin } from "../../../services/payment"

import validate from "../../../helpers/formValidationRules"
import useForm from "../../../hooks/useForm"

import wsErrors from "../../../../static/data/errors.json"

import TextTitle from "../blocks/title/TextTitle"
import ButtonArrowLeft from "../../elements/buttons/ButtonArrowLeft"
import RoundedButton from "../../elements/buttons/RoundedButton"
import InputText from "../../elements/inputs/InputText"
import Checkbox from "../../elements/checkbox/Checkbox"
import iconLoading from "../../../images/loading.png"

import StepChargeStyle from "./StepCharge.style"
import Modal from "../modal/Modal"
import ContentModal from "../modal/ContentModalForm"
import { formattedMoney } from "../../../hooks/useFormatStrings"

const StepCharge = ({ handleNextStep }) => {
  const classNameID = `m-step-charge`
  const { currentIndex, accounts, detailsSummary, loading, setloading, setSuccessDetailsPayment } = useContext(SiteContext)
  const [showModal, setShowModal] = useState(false)
  const [maskCardNumber, setMaskCardNumber] = useState("9999 9999 9999 9999")
  const [cardType, setCardType] = useState(null)
  const [errorCard, setErrorCard] = useState('')
  const [loadingBinCheck, setLoadingBinCheck] = useState(false)
  const [paymentError, setPaymentError] = useState({})


  const dataPayment = {
    nombre: "",
    numTarjeta: "",
    vencimiento: "",
    cvv: "",
    cardType: "",
    acceptedPrivacyNotice: false,
  }

  const { inputs, handleInputChange, handleSubmit, errors } = useForm(
    dataPayment,
    formValidCallback,
    validate
  )

  async function formValidCallback(errorsForm) {
    if (Object.keys(errorsForm).length > 0) {
      return
    }
    setloading(true)

    const cardData = { ...inputs };
    // const dateFormated = cardData.vencimiento.split("/").join("")
    cardData.vencimiento = cardData.vencimiento
    cardData.numTarjeta = cardData.numTarjeta.replace(/\s/g, "");
    cardData.cardType = cardType

    try {
      const paymentIntentId = await createPaymentIntent(accounts[currentIndex].accountNumber)

      if (paymentIntentId) { 
        const paymentResponse = await confirmPaymentIntent(paymentIntentId, cardData)
        
        if (paymentResponse.status === 200) {
          setloading(false)
          const { data } = paymentResponse
          setSuccessDetailsPayment(data['data'])
          handleNextStep(3)
        } else {
          setShowModal(true)
          setloading(false)
        }
      }
    } catch (error) { 
      setPaymentError(error)
      setloading(false)
      setShowModal(true)
    }

  }

  const handleCheckbox = active => {
    inputs.acceptedPrivacyNotice = active
  }

  const checkCardType = (cardNumber) => { 
    setLoadingBinCheck(true)
    checkBin(cardNumber).then(response => {
      const { data } = response
      if (data) { 
        setLoadingBinCheck(false)
        setCardType(data.type)
        setErrorCard('')
      }
    }).catch(error => {
      const errorType = error?.response?.data.error;
      const errorObject = Object.values(wsErrors).find(e => e.error === errorType);
      const errorMessage = errorObject?.message || wsErrors['unexpected_error'].message;
      
      setLoadingBinCheck(false)
      setErrorCard(errorMessage)
      setCardType(null)
    })
  }

  const handleCardNumberChange = (event) => { 
    const cardNumber = event.target.value.replace(/\s/g, "").replace(/_/g, "");
    const isAmex = cardNumber && (cardNumber.startsWith("37") || cardNumber.startsWith("34"));
    const cardLength = cardNumber.length;

    if (isAmex) { 
      setMaskCardNumber("9999 999999 99999")
      if (cardLength === 15) {
        checkCardType(cardNumber.substring(0, 6))
      }
    } else {
      setMaskCardNumber("9999 9999 9999 9999")
      if (cardLength === 16) {
        checkCardType(cardNumber.substring(0, 6))
      }
    }
    handleInputChange(event)
    setErrorCard(false)
    setCardType(null)
  }

  return (
    <StepChargeStyle className={classNameID}>
      <ButtonArrowLeft
        onClick={() => handleNextStep(1)}
        className={`${classNameID}__button-back`}
      >
        Regresar
      </ButtonArrowLeft>
      <TextTitle textBlue="Cargo" textGreen="" />
      <div className={`${classNameID}__container-contract-pay`}>
        <div className={`${classNameID}__container-contract`}>
          <p className={`${classNameID}__label`}>Contrato:</p>
          <p>{accounts[currentIndex].label}</p>
        </div>
        <div className={`${classNameID}__container-pay`}>
          <p className={`${classNameID}__label`}>Total a pagar:</p>
          <p>{formattedMoney(detailsSummary.amountDue)}</p>
        </div>
      </div>
      <div className={`${classNameID}__container-form`}>
        <p className={`${classNameID}__payment-method`}>Método de pago:</p>
        <form
          id="form-payment"
          noValidate
          className={`${classNameID}__formulario`}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className={`${classNameID}__wrapper-input-name`}>
            <InputText
              id="nombre"
              name="nombre"
              className={`${classNameID}__input`}
              type="text"
              placeholder="Nombre de la tarjeta"
              handleInputChange={handleInputChange}
              error={errors.nombre}
            />
            <p
              className={`${classNameID}__label-optional-text`}
            >{`(Opcional si su tarjeta es de débito)`}</p>
          </div>
          <div className={`${classNameID}__wrapper-input-card`}>
            <InputText
              id="numTarjeta"
              name="numTarjeta"
              className={`${classNameID}__input`}
              placeholder="* No. de Tarjeta"
              handleInputChange={handleCardNumberChange}
              required
              mask={maskCardNumber}
              error={errors.numTarjeta}
            />
             {loadingBinCheck && (
              <p className={`${classNameID}__card-type--loading`}>cargando...</p>
            )}
            {errorCard !== '' && (
              <p className={`${classNameID}__card-type--error`}>{ errorCard }</p>
            )}
          </div>
          <div className={`${classNameID}__container-last-row`}>
            <InputText
              id="vencimiento"
              name="vencimiento"
              className={`${classNameID}__input`}
              placeholder="*Vencimiento"
              handleInputChange={handleInputChange}
              required
              mask="99/99"
              error={errors.vencimiento}
            />
            <InputText
              id="cvv"
              name="cvv"
              className={`${classNameID}__input`}
              placeholder="*CVV"
              handleInputChange={handleInputChange}
              required
              mask="999"
              error={errors.cvv}
            />
          </div>
          <div className={`${classNameID}__container-terms`}>
            <Checkbox
              className={`${classNameID}__checkbox`}
              handleCheckbox={handleCheckbox}
              error={errors.acceptedPrivacyNotice}
            >
              <p>
                Acepto los <span> </span>
                <a
                  href="https://www.aguasdesaltillo.com/aviso-de-privacidad/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Términos y Condiciones de uso
                </a>
              </p>
            </Checkbox>
          </div>
          <div className={`${classNameID}__container_button`}>
            <RoundedButton
              className={`${classNameID}__button is-pink`}
              onClick={handleSubmit}
              isDisabled={loading || loadingBinCheck || errorCard !== ''}
            >
              {loading ? (
                <>
                  <img src={iconLoading} alt="loading" /> Cargando...
                </>
              ) : (
                "Pagar"
              )}
            </RoundedButton>
          </div>
        </form>
      </div>
      {
        showModal && (
          <Modal onClose={() => {setShowModal(false)}} >
            <ContentModal setShowModal={setShowModal} paymentErrors={paymentError} />
          </Modal>
        )
      }
    </StepChargeStyle>
  )
}

StepCharge.propTypes = {
  handleNextStep: func,
  data: array,
}

StepCharge.defaultProps = {
  handleNextStep: () => {},
  data: [],
}

export default StepCharge

import React, { useState, useContext, useEffect } from "react"
import SiteContext from "../../../context/site.context"

import ContractList from "./ContractList"
import AddNewContract from "./AddNewContract"
import ContractsStyle from "./Contracts.style"
import LoadingView from "../loading/LoadingView"

const Contracts = () => {
  const classNameID = `m-contracts`
  const { accounts, loadAccount, fetchAccounts } = useContext(SiteContext)
  const [steps, setSteps] = useState(0)

  function getStep() { 
    switch (steps) {
      case 0:
        return <ContractList handleNextStep={setSteps} />
      case 1:
        return <AddNewContract handleNextStep={setSteps} />
      case 2:
      default:
        break
    }
  }

  useEffect(() => {
    if (loadAccount === null && accounts.length === 0) {
      fetchAccounts()
    }
  }, [loadAccount])

  return (
    <ContractsStyle className={classNameID} >
      {loadAccount && <LoadingView />} 
      {loadAccount !== null && !loadAccount && accounts.length > 0 && getStep() } 
      {loadAccount !== null && !loadAccount && accounts.length === 0 && (
        <AddNewContract firstContract />
       )}
    </ContractsStyle>
  )
}

export default Contracts

import apiWebServices from './api-web-services';

const baseUrl = process.env.GATSBY_BASE_URL;

export const summary = async (accountNumber) => {
  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/account/${accountNumber}/summary`,
      {},
    );
    const { data } = response;
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkBin = async (cardNumber) => { 

  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/wallet/bincheck/${cardNumber}`,
      {},
    );
    const { data } = response;
    return data;
  } catch (error) {
    throw error;
  }

}

export const createPaymentIntent = async (accountNumber) => {
  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/payment_intent/`,
      { 'account': accountNumber, },
    );

    // const response = await axios.get(`https://private-c26c0c-agsal.apiary-mock.com/v1/payment-intent`,
    //   {
    //     headers: { 'Content-Type': 'application/json',}
    //   }
    // );
    const { data } = response;
    return data['data']['id'];
  } catch (error) {
    throw error.response.data;
  }
}

export const confirmPaymentIntent = async (paymentIntentId, cardData) => {
  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/payment_intent/${paymentIntentId}/confirm`,
      {
        source: 2,
        card: {
          pan: cardData.numTarjeta,
          name: cardData.nombre,
          validThru: cardData.vencimiento,
          cvv: cardData.cvv,
          type: cardData.cardType,
        },
      },
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
}


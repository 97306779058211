import React from "react"
import Seo from "../components/seo/Seo"
import Home from "../components/modules/home/Home"

const IndexPage = () => {
  return <Home />
}

export default IndexPage

export const Head = () => <Seo title={"Oficina Virtual"} />

import React from "react"
import { string, node, func } from "prop-types"
import {CardLink, CardButton} from "./Cards.style"

const Cards = ({ children, title, onClick, className, to }) => {
  const classNameID = `m-card`
  if (to) {
    return (
      <CardLink to={to} onClick={onClick} className={className}>
        {children}
        <div dangerouslySetInnerHTML={{ __html: title }} className={`${classNameID}__title`} />
      </CardLink>
    )
  }
  return (
    <CardButton onClick={onClick} className={className}>
      {children}
      <div dangerouslySetInnerHTML={{ __html: title }} className={`${classNameID}__title`} />
    </CardButton>
  )
}

Cards.propTypes = {
  children: node.isRequired,
  onClick: func,
  className: string,
  to: string,
}

Cards.defaultProps = {
  title: "",
  onClick: () => null,
  className: "",
  to: "",
}

export default Cards

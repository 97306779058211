import apiWebServices from './api-web-services';

const baseUrl = process.env.GATSBY_BASE_URL;

export const invoices = async (accountNumber) => {
  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/account/${accountNumber}/invoices`,
      { invoiceType:"water" },        
    );
    
    const { data } = response;
    
    return data; 
  } catch (error) {
    throw error
  }
};

export const invoicesSanitation = async (accountNumber) => {
  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/account/${accountNumber}/invoices`,
      { invoiceType:"sanitation" },          
    );
    
    const { data } = response;
    
    return data; 
  } catch (error) {
    throw error
  }
};


export const pdfInvoices = async (invoiceNumber, account) => {
  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/invoice/${invoiceNumber}/pdf`,
      { account },
      {
        responseType: 'arraybuffer'
      },           
    );
    
    return response; 
  } catch (error) {

    throw error;
  }
};

export const xmlInvoices = async (invoiceNumber, account) => {
  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/invoice/${invoiceNumber}/xml`,
      { account },
      {
        responseType: 'arraybuffer'
      },           
    );
    return response; 
  } catch (error) {
    throw error;
  }
};

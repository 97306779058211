import React from "react"
import Seo from "../components/seo/Seo"
import PayOnLine from "../components/modules/payOnLine/PayOnLine"

const PayOnLinePage = () => {
  return <PayOnLine />
}

export default PayOnLinePage

export const Head = () => <Seo title={'Pago en línea'} />;

import React from "react"
import Seo from "../components/seo/Seo"
import Login from "../components/modules/login/Login"

const LoginPage = () => {
  return <Login />
}

export default LoginPage

export const Head = () => <Seo title={'Login'} />;

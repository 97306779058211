
export const getMonths = (period) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Deciembre",
  ]
  if (period >= 1 && period <= 12) {
    return months[period - 1]
  } else {
    return ""
  }
}

export const getStatus = (status) => { 
  switch (status) {
    case 1:
      return "Provisional";
    case 2:
      return "Pendiente";
    case 3:
      return "Pagada";
    case 4:
      return "Impadaga";
    case 5:
      return "Bloqueada";
    case 6:
      return "Abonada";
    case 7:
      return "Amortizada";
    case 8:
      return "Descargada";
    case 9:
      return "Abono";
    case 10:
      return "A Remesar";
    default:
      return "No Preparada";
  }
}

export const formatDate = (date) => { 
  const currentDate = new Date(date); 
  const options = { timeZone: 'America/Mexico_City', day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const formattedDate = currentDate.toLocaleDateString('es-Mx', options).replace(',', ' -');
  return formattedDate;
}

export function formattedMoney(value) {
  const amount = parseFloat(value);
  return new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}

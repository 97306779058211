import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"

import LoadingView from "../modules/loading/LoadingView"

const PrivateRoute = ({ component: Component, location, ...rest }) => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isLoggedIn() && location.pathname !== `/login`) {
      navigate("/login");
    } else {
      setLoading(false);
    }
  }, [location.pathname]);

  if (loading) {
    return <LoadingView />;
  }

  return <Component {...rest} />;
}

export default PrivateRoute

import React, { useContext } from "react"
import SiteContext from "../../../context/site.context"

import { func, array } from "prop-types"

import TextTitle from "../blocks/title/TextTitle"
import ButtonArrowLeft from "../../elements/buttons/ButtonArrowLeft"
import RoundedButton from "../../elements/buttons/RoundedButton"

import iconLoading from "../../../images/loading.png"

import StepSummaryStyle from "./StepSummary.style"
import { formattedMoney } from "../../../hooks/useFormatStrings"

const StepSummary = ({ handleNextStep }) => {
  const classNameID = `m-step-summary`
  const { currentIndex, accounts, loading, detailsSummary } = useContext(SiteContext)

  const createRow = (label, value, className) => {
    return (
      <div className={`${classNameID}__summary-row`}>
        <p className={`${classNameID}__summary-name`}>{label}</p>
        <p className={`${classNameID}__summary-value ${className}`}>{value}</p>
      </div>
    )
  }

  const getSummary = () => {
    return (
      <div className={`${classNameID}__container-summary`}>
        {createRow("Contrato:", accounts[currentIndex].label, "is-pink")}
        {createRow("Agua:", formattedMoney(detailsSummary.water), "")}
        {createRow("Saneamiento:",  formattedMoney(detailsSummary.sanitation), "")}
        {createRow("Contratación:", formattedMoney(detailsSummary.connectionFee), "")}
        {createRow("Reconexión:", formattedMoney(detailsSummary.reconnectionFee), "")}
        {createRow("Otros Servicios:", formattedMoney(detailsSummary.otherServices), "")}
        {createRow("Total:", formattedMoney(detailsSummary.amountDue), "is-pink")}
      </div>
    )
  }

  return (
    <StepSummaryStyle className={classNameID}>
      <ButtonArrowLeft
        onClick={() => handleNextStep(0)}
        className={`${classNameID}__button-back`}
      >
        Regresar
      </ButtonArrowLeft>
      <TextTitle textBlue="Resumen de" textGreen="Pago" />
        {getSummary()}
      <div className={`${classNameID}__container_button`}>
        <RoundedButton
          className={`${classNameID}__button is-pink`}
          onClick={() => handleNextStep(2)}
          disabled={loading}
        >
          {loading ? (
            <>
              <img src={iconLoading} alt="loading" /> cargando...
            </>
          ) : (
            "Pagar"
          )}
        </RoundedButton>
      </div>
    </StepSummaryStyle>
  )
}

StepSummary.propTypes = {
  handleNextStep: func,
  data: array,
}

StepSummary.defaultProps = {
  handleNextStep: () => {},
  data: [],
}

export default StepSummary

import React, { useState, useContext, useEffect } from "react"
import SiteContext from "../../../context/site.context"

import { func, string } from "prop-types"
import OtpInput from "react-otp-input"

import RoundedButton from "../../elements/buttons/RoundedButton"

import { ModalCard, StyleModalText } from "./VerificationCode.style"
import TimerTextButton from "../../elements/buttons/TimerTextButton"

import iconLoading from "../../../images/loading.png"

import { resendOtp } from "../../../services/auth"

const VerificationCode = ({ onClick, errorMessage }) => {
  const classNameID = "verification-code"
  const { loading } = useContext(SiteContext)
  const [otp, setOtp] = useState("")
  const [isErrorVisble, setIsErrorVisible] = useState(false)

  const sendCodeVerification = () => {
    if (otp.length < 6 || otp === "") {
      setIsErrorVisible(true)
    } else {
      setIsErrorVisible(false)
      onClick(otp)
    }
  }

  const handleOtpChange = otp => {
    setOtp(otp)
    setIsErrorVisible(false)
  }

  const reSentVerificationCode = async () => { 
    clearOtpFields()
    try {
      await resendOtp()
    } catch (e) {
    }
  }

  const clearOtpFields = () => {
    setOtp("")
  }

  useEffect(() => {
    if (errorMessage !== "") {
      setIsErrorVisible(true)
    }
  }, [errorMessage])

  return (
    <ModalCard className={classNameID}>
      <StyleModalText>
        <h1 className={`${classNameID}__title`}>
          Ingresa el código de verificación
        </h1>
        <p className={`${classNameID}__description`}>
          Te hemos enviado un código de verificación a tu celular.
        </p>
      </StyleModalText>
      <OtpInput
        value={otp}
        containerStyle={`${classNameID}__otp-container`}
        inputStyle={`${classNameID}__otp-input ${
          isErrorVisble ? "is-error" : ""
        }`}
        inputType="number"
        onChange={handleOtpChange}
        numInputs={6}
        renderInput={props => <input {...props} />}
      />
      <p
        className={`${classNameID}__error ${
          isErrorVisble ? "error-visible" : ""
        }`}
      >
        {errorMessage !== "" ? errorMessage : "Código de verificación inválido"}
      </p>
      <TimerTextButton
        isDisabled={loading}
        action={() => {
          reSentVerificationCode()
        }}
      />
      <RoundedButton
        className={`${classNameID}__button is-blue`}
        onClick={sendCodeVerification}
        isDisabled={loading}
      >
        {loading ? (
          <>
            <img src={iconLoading} alt="loading" /> Cargando...
          </>
        ) : (
          "Aceptar"
        )}
      </RoundedButton>
    </ModalCard>
  )
}

VerificationCode.propTypes = {
  onClick: func,
  errorMessage: string,
}

VerificationCode.defaultProps = {
  onClick: () => {},
  errorMessage: "",
}

export default VerificationCode

/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */
const validateEmail = function (value) {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
}

const validatePassword = function (value) {
  if (!/(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;<>,.?~]).{8,}/.test(value)) {
    return false
  }
  return true
}

export default function validate(values) {
  const errors = {}

  // =======================================
  // validation for empty required fields
  // =======================================
  // eslint-disable-next-line guard-for-in
  for (const key in values) {
    const element = document.getElementById(key)
    if (element && element.hasAttribute("required") && !values[key].trim()) {
      errors[key] = `*Campo obligatorio`
    }
  }

  const expiryRegex = /^(0[1-9]|1[0-2])\/\d{2}$/; // Formato MM/YY

  if (values.email && !validateEmail(values.email) && !errors.email) {
    errors.email = "Formato de email inválido"
  }
  if (values.celular && !/^[0-9]{10}$/.test(values.celular) && !errors.celular) {
    errors.celular = "Formato de teléfono inválido"
  }
  if (values.telefono !== undefined && !/^[0-9]{10}$/.test(values.telefono) && !errors.telefono) {
    errors.telefono = "Formato de teléfono inválido"
  }
  if (values.contrato && (values.contrato.trim().length < 6 || values.contrato.trim().length > 7)) {
    errors.contrato = "El contrato debe tener entre 6 y 7 dígitos";
  }

  if (values.password && !validatePassword(values.password)) {
    errors.password = "Formato de contraseña inválido.";
  }

  if (values.confirmPassword && values.password !== values.confirmPassword) {
    errors.confirmPassword = "Las contraseñas no coinciden"
  }
  if (values.cvv && values.cvv.replace(/\D/g, "").length !== 3) {
    errors.cvv = "El CVV inválido";
  }
  // if (values.numTarjeta && values.numTarjeta.replace(/\D/g, "").length !== 16) {
  //   errors.numTarjeta = "Número de tarjeta inválido";
  // }
  if (values.numTarjeta) {
    const cardNumber = values.numTarjeta.replace(/\D/g, "");
    const isAmex = cardNumber.startsWith("34") || cardNumber.startsWith("37");
  
    if ((isAmex && cardNumber.length !== 15) || (!isAmex && cardNumber.length !== 16)) {
      errors.numTarjeta = "Número de tarjeta inválido";
    }
  }

  if (values.vencimiento && !expiryRegex.test(values.vencimiento)) {
    errors.vencimiento = "Fecha inválida";
  }
  if (values.hasOwnProperty("acceptedPrivacyNotice") && values.acceptedPrivacyNotice === false) {
    errors.acceptedPrivacyNotice = `*Campo obligatorio`
  }

  // Validación de la fecha de nacimiento
  const selectedDate = new Date(values.birthdate);
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 100);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  if (selectedDate < minDate || selectedDate > maxDate) {
    errors.birthdate = "Fecha invalida";
  }

  return errors
}

import * as React from 'react'
import { string, number, oneOfType } from 'prop-types'

function SvgArrow({ width, height, stroke }) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18.313 14.187">
        <path fill="none" fillRule='evenodd' stroke={stroke} strokeWidth='3px' d="M495.616,132.507h16.5" transform="translate(-493.812 -125.625)" />
        <path fill="none" fillRule='evenodd' stroke={stroke} strokeWidth='3px' d="M501.728,126.672L495.9,132.72l5.827,6.049" transform="translate(-493.812 -125.625)" />
      </svg>
    )
}

SvgArrow.propTypes = {
    stroke: string,
    height: oneOfType([string, number]),
    width: oneOfType([string, number]),
}

SvgArrow.defaultProps = {
    stroke: '#000',
    height: 14,
    width: 18,
}

export default React.memo(SvgArrow)
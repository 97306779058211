import styled from 'styled-components'
import { device } from '../../../theme/mediaQueries'

const HeaderStyled = styled.header`
  position: relative;
  width: calc(100% - 24px);
  z-index: 1000;
  margin-top: 12px;
  left: 12px;
  right: 12px;

  .m-header_container {
    background-color: ${({ theme }) => theme.colors.blue};
    border-radius: 35px 0 35px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    position: relative;

    &.bg-white {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  .m-header_logo {
    width: 81px;
    height: 36px;
  }

  .m-header__container-sesion {
    display: flex;
    align-items: center;
    position: absolute;
    right: 16px;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    
    .m-header__icon-user {
      align-items: center;
      background-color: #e6f2fd;
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      overflow: hidden;
      padding: 2px;
      width: 40px;

      img {
        border-radius: inherit;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
    .m-header__icon-user .m-header__icon-user-default { 
      height: 18px;
      object-fit: contain;
      width: 18px;
    }
    .m-header__user-name { 
      display: none;
    }
  }

  .m-header__container-options-sesion {
    position: absolute;
    right: 0;
    bottom: -140px;
    width: 220px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0 35px 0 35px;
    box-shadow: 0px 10px 20px 0px rgba(0, 94, 187, 0.15), 0px 1.5px 0.5px 0px #FFF inset, 0px -1.5px 0.5px 0px rgba(0, 83, 165, 0.30) inset;

    .m-header__link {
      padding-left: 30px;
      height: 43px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.blueDark};

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }

    .m-header__divider {
      width: 100%;
      height: 1px;
      background-color: #e0f0fe;
    }
  }

  @media ${device.sm} {
    margin-top: 18px;
    width: calc(100% - 80px);
    left: 40px;
    right: 40px;
    .m-header_container {
      height: 70px;
    }
    .m-header_logo {
      width: 95px;
      height: 42px;
    }
    .m-header__container-sesion {
      .m-header__icon-user {
        width: 50px;
        height: 50px;
      }
    }

    .m-header__container-options-sesion {
      width: 267px;
    }
  }

  @media ${device.md} {
    margin-top: 20px;
    width: calc(100% - 100px);
    left: 50px;
    right: 50px;
    .m-header_container {
      height: 82px;
    }

    .m-header__container-sesion {
      .m-header__user-name { 
        display: block;
        margin-right: 12px;
        font-size: 16px;
        color: ${({ theme }) => theme.colors.white};

        &.is-blue {
          color: ${({ theme }) => theme.colors.blue};
        }
      }
    }
  }

  @media ${device.lg} {
    margin-top: 22px;
    width: calc(100% - 120px);
    left: 60px;
    right: 60px;
    .m-header_container {
      height: 85px;
    }
    .m-header_logo {
      width: 103px;
      height: 46px;
    }
    .m-header__container-sesion {
      .m-header__icon-user {
        width: 55px;
        height: 55px;
      }
      .m-header__icon-user .m-header__icon-user-default { 
        height: 22px;
        width: 22px;
      }
    }
  }

  @media ${device.xl} {
    width: 1720px;
    padding: 0;
    margin: 30px auto 0;
    left: 0;
    right: 0;
    .m-header_container {
      height: 100px;
    }
    .m-header_logo {
      width: 122px;
      height: 54px;
    }
  }
`

export default HeaderStyled

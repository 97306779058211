import React, { useState, useContext, useEffect } from "react"
import SiteContext from "../../../context/site.context"

import StepPay from "./StepPay"
import StepSummary from "./StepSummary"
import StepCharge from "./StepCharge"
import PaySuccessful from "./PaySuccessful"
import LoadingView from "../loading/LoadingView"
import ViewContractEmpty from "../contracts/ViewContractEmpty"

import { PayOnLineStyled } from "./PayOnLine.style"

const PayOnLine = () => {
  const classNameID = `m-pay-on-line`
  const [steps, setSteps] = useState(0)

  const { accounts, loadAccount, fetchAccounts } = useContext(SiteContext)

  useEffect(() => {
    if (loadAccount === null && accounts.length === 0) {
      fetchAccounts()
    }
  }, [loadAccount])

  function getStep() {
    switch (steps) {
      case 0:
        return <StepPay handleNextStep={setSteps} />
      case 1:
        return <StepSummary handleNextStep={setSteps} />
      case 2:
        return <StepCharge handleNextStep={setSteps} />
      case 3:
        return <PaySuccessful />
      default:
        break
    }
  }

  return (
    <PayOnLineStyled className={classNameID} >
      {loadAccount ? <LoadingView /> : getStep()}
      {loadAccount !== null && !loadAccount && accounts.length === 0 && (
         <ViewContractEmpty />
      )} 
    </PayOnLineStyled>
  )
}

export default PayOnLine

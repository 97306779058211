import React, { useEffect, useState, useRef, useContext } from "react"
import SiteContext from "../../../context/site.context"
import { Link, navigate } from "gatsby"
import { getUser, isLoggedIn, logout } from "../../../services/auth"

import HeaderStyled from "./Header.style"
import SvgLogoDark from "../../elements/svgs/SvgLogoDark"
import SvgLogoWhite from "../../elements/svgs/SvgLogoWhite"
import svgUser from "../../../images/svgs/user.svg"

const Header = () => {
  const classNameID = `m-header`
  const { headerWhite } = useContext(SiteContext)
  const [showSesion, setShowSesion] = useState(false)
  const [user, setUser] = useState(getUser())
  const buttonRef = useRef(null)
  const isLogged = isLoggedIn()

  const handleShowSesion = () => {
    setShowSesion(!showSesion)
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShowSesion(false)
      }
    }

    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  useEffect(() => {   
    setUser(getUser())
  }, [isLogged])

  const closeSesion = () => {
    setShowSesion(false)
    logout(() => navigate(`/`))
  }

  return (
    <HeaderStyled className={classNameID}>
      <div className={`${classNameID}_container ${headerWhite ? 'bg-white' : ''} `}>
        <Link className="container-logo" to={isLogged ? "/cuenta" : "/"}>
          {headerWhite ? <SvgLogoWhite className={`${classNameID}_logo`} /> : <SvgLogoDark className={`${classNameID}_logo`} />}
        </Link>
        {isLogged && (
          <div
            role="button"
            tabIndex={0}
            className={`${classNameID}__container-sesion`}
            ref={buttonRef}
            onClick={handleShowSesion}
            onKeyDown={event => {
              if (event.key === "Enter") {
                handleShowSesion()
              }
            }}
          >
            <div className={`${classNameID}__user-name ${headerWhite ? 'is-blue' : ''} `}>{user.name}</div>
            <div className={`${classNameID}__icon-user`}>
              {
                user.profilePic !== null ? (
                  <img src={user.profilePic} alt="user" />
                ) : (
                  <img className={`${classNameID}__icon-user-default`} src={svgUser } alt="user" />
                )
              }
            </div>
          </div>
        )}
      </div>
      {showSesion && (
        <div className={`${classNameID}__container-options-sesion`}>
          <Link className={`${classNameID}__link`} to="/cuenta">
            Mi cuenta
          </Link>
          <div className={`${classNameID}__divider`} />
          <Link className={`${classNameID}__link`} to="/cambiar-contrasena">
            Cambiar Contraseña
          </Link>
          <div className={`${classNameID}__divider`} />
          <div
            role="button"
            tabIndex={0}
            onClick={closeSesion}
            className={`${classNameID}__link`}
            onKeyDown={event => {
              if (event.key === "Enter") {
                closeSesion()
              }
            }}
          >
            Cerrar Sesión
          </div>
        </div>
      )}
    </HeaderStyled>
  )
}

export default Header

import React from "react"
import Seo from "../components/seo/Seo"
import RecoverPassword from "../components/modules/recoverPassword/RecoverPassword"

const RecuperarContrasenaPage = () => {
  return <RecoverPassword />
}

export default RecuperarContrasenaPage

export const Head = () => <Seo title={"Recuperar Contraseña"} />
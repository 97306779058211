import * as React from 'react'
import { string, number, oneOfType } from 'prop-types'

const SvgLogoWhite = ({ width, height, className }) => (
  <svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530 230" xmlSpace="preserve">
    <path fill="#0F93ED" d="M250.3 142.1h-8.5c-.6 0-1.1-.2-1.3-.9l-3.2-9.3h-20l-3.2 9.3c-.2.7-.7.9-1.3.9h-8.5c-.8 0-1.2-.5-.9-1.3L222 89.1c.2-.6.7-.9 1.3-.9h7.7c.6 0 1.2.3 1.5.9l18.7 51.7c.3.8 0 1.3-.9 1.3zm-15.9-19.2-7.1-21-7.1 21h14.2z" />
    <g fill="#0F93ED">
      <path d="M290.7 105.5v36.2c0 12-11.2 15.6-19.8 15.6-6.5 0-12.3-1.9-16.2-4.6-.5-.4-.6-.9-.3-1.5l2.5-4.6c.4-.6.8-.8 1.5-.5 3.5 1.9 7 2.8 11.6 2.8s11.2-1.8 11.2-8v-4.2c-2.8 3.2-6.6 5.5-11.7 5.5-10.5 0-18.2-8.8-18.2-19.4s7.7-19.4 18.2-19.4c4.8 0 8.7 2.2 11.5 5.4l.2-3.4c.1-.7.5-1.2 1.2-1.2h7.1c.7.2 1.2.6 1.2 1.3zm-10.2 17.3c0-5.3-4.1-10.1-9.6-10.1-5.6 0-9.7 4.5-9.7 10.1 0 5.7 4.1 10.2 9.7 10.2 5.5.1 9.6-4.8 9.6-10.2zM333 105.5v35.4c0 .7-.5 1.2-1.2 1.2h-7.5c-.7 0-1.1-.5-1.2-1.2l-.3-4.2c-2.6 3.7-6.5 6.4-11.2 6.4-8.5 0-14.3-5.9-14.3-15.9v-21.6c0-.7.5-1.2 1.2-1.2h7.9c.7 0 1.2.5 1.2 1.2v19.8c0 6.2 3.6 8.4 7.2 8.4 5.5 0 8.2-4.6 8.2-7.3v-20.9c0-.7.5-1.2 1.2-1.2h7.9c.4 0 .9.4.9 1.1zM378.1 105.5v35.4c0 .7-.5 1.2-1.2 1.2h-7.1c-.7 0-1.1-.5-1.2-1.2l-.3-3.2c-2.7 3.2-6.5 5.4-11.4 5.4-10.5 0-18.2-8.8-18.2-19.8s7.7-19.8 18.2-19.8c4.8 0 8.6 2.3 11.5 5.5l.2-3.5c.1-.7.5-1.2 1.2-1.2h7.1c.7.1 1.2.5 1.2 1.2zm-10.3 17.7c0-5.7-4.1-10.5-9.6-10.5-5.6 0-9.7 4.5-9.7 10.5 0 6.1 4.1 10.6 9.7 10.6 5.6.1 9.6-4.9 9.6-10.6zM412.6 131.5c0 7.3-7.5 11.5-14.4 11.5-6.5 0-11.7-3.2-14.4-6.3-.5-.5-.3-1.1 0-1.5l3-4.2c.5-.6 1-.5 1.7.1 3.1 2 6.4 3.5 9.4 3.5 2.5 0 4.9-.8 4.9-3.2 0-6-18.2-3.7-18.2-16.4 0-7.2 6.3-11.5 13.1-11.5 4.9 0 9.8 2 12.9 5.7.5.5.3 1.2-.1 1.6l-2.9 3.9c-.5.6-1 .5-1.7-.1-2.9-1.9-4.7-2.9-7.4-2.9-2.4 0-4.2 1.3-4.2 3.2-.1 6.2 18.3 2.8 18.3 16.6z" />
    </g>
    <g fill="#0F93ED">
      <path d="M243.3 164v53.9c0 .7-.5 1.2-1.2 1.2H235c-.7 0-1.1-.5-1.2-1.2l-.3-3.2c-2.7 3.2-6.5 5.4-11.4 5.4-10.5 0-18.2-8.8-18.2-19.8s7.7-19.8 18.2-19.8c4.5 0 8.2 2 10.9 4.9V164c0-.7.5-1.2 1.2-1.2h7.9c.7.1 1.2.5 1.2 1.2zm-10.2 36.2c0-5.7-4.1-10.5-9.6-10.5-5.6 0-9.7 4.5-9.7 10.5 0 6.1 4.1 10.6 9.7 10.6 5.5 0 9.6-4.9 9.6-10.6zM283.3 204h-24.2c1 5 4.9 7.9 10.8 7.9 3 0 5.4-.8 8-2.6.7-.5 1.2-.5 1.7.1l3.4 3.3c.5.5.5 1.1-.1 1.7-3.6 3.6-8.5 5.8-14 5.8-11.8 0-19.9-8.7-19.9-19.8s8.1-19.8 19-19.8 17.9 8.7 17.9 18.9c.1 3-.3 4.5-2.6 4.5zm-24.3-6.6h18c-.7-5.3-4.3-8.2-8.9-8.2-4.6-.1-8.2 2.9-9.1 8.2z" />
    </g>
    <g fill="#0F93ED">
      <path d="M336.3 204.1c0 10.2-9.3 15.9-18.7 15.9-7.9 0-14.6-4.2-18.2-8.4-.4-.5-.4-1.1.1-1.6l4.6-5c.5-.5 1.1-.6 1.7-.1 3.7 3.2 7.3 5.2 12 5.2 4.5 0 8.2-2.5 8.2-6.3 0-9.5-25.6-6.6-25.6-24.3 0-9.4 7.9-15.1 16.8-15.1 6.7 0 12.2 2.8 16.5 7.2.5.5.5 1 0 1.6l-4.2 5.2c-.5.6-1 .7-1.6.2-3.8-2.9-6.5-4.3-10.3-4.3-4.3 0-6.9 2.3-6.9 5.5 0 9.6 25.6 5.6 25.6 24.3zM380.3 182.5v35.4c0 .7-.5 1.2-1.2 1.2H372c-.7 0-1.1-.5-1.2-1.2l-.3-3.2c-2.7 3.2-6.5 5.4-11.4 5.4-10.5 0-18.2-8.8-18.2-19.8s7.7-19.8 18.2-19.8c4.8 0 8.6 2.3 11.5 5.5l.2-3.5c.1-.7.5-1.2 1.2-1.2h7.1c.7.1 1.2.5 1.2 1.2zm-10.2 17.7c0-5.7-4.1-10.5-9.6-10.5-5.6 0-9.7 4.5-9.7 10.5 0 6.1 4.1 10.6 9.7 10.6 5.5 0 9.6-4.9 9.6-10.6zM388.4 162.9h7.9c.7 0 1.2.5 1.2 1.2V218c0 .7-.5 1.2-1.2 1.2h-7.9c-.7 0-1.2-.5-1.2-1.2v-54c0-.7.5-1.1 1.2-1.1zM426.1 182.5v6c0 .7-.5 1.2-1.2 1.2h-5.4V218c0 .7-.5 1.2-1.2 1.2h-7.9c-.7 0-1.2-.5-1.2-1.2v-28.3H404c-.7 0-1.2-.5-1.2-1.2v-6c0-.7.5-1.2 1.2-1.2h5.4V171c0-.7.5-1.2 1.2-1.2h7.9c.7 0 1.2.5 1.2 1.2v10.4h5.4c.6 0 1 .4 1 1.1z" />
    </g>
    <g fill="#0F93ED">
      <path d="M431.1 168.6c0-3.1 2.5-5.6 5.6-5.6s5.5 2.5 5.5 5.6-2.5 5.6-5.5 5.6c-3.1 0-5.6-2.5-5.6-5.6zm1.7 12.8h7.9c.7 0 1.2.5 1.2 1.2V218c0 .7-.5 1.2-1.2 1.2h-7.9c-.7 0-1.2-.5-1.2-1.2v-35.4c.1-.8.5-1.2 1.2-1.2zM450.8 162.9h7.9c.7 0 1.2.5 1.2 1.2V218c0 .7-.5 1.2-1.2 1.2h-7.9c-.7 0-1.2-.5-1.2-1.2v-54c0-.7.5-1.1 1.2-1.1z" />
    </g>
    <g fill="#0F93ED">
      <path d="M468.8 162.9h7.9c.7 0 1.2.5 1.2 1.2V218c0 .7-.5 1.2-1.2 1.2h-7.9c-.7 0-1.2-.5-1.2-1.2v-54c0-.7.5-1.1 1.2-1.1zM483.6 200.3c0-11.1 8.7-19.8 19.6-19.8 10.9 0 19.6 8.7 19.6 19.8s-8.7 19.8-19.6 19.8-19.6-8.7-19.6-19.8zm29.4 0c0-6.1-4.4-10.4-9.8-10.4-5.5 0-9.8 4.3-9.8 10.4s4.3 10.4 9.8 10.4c5.4 0 9.8-4.3 9.8-10.4z" />
    </g>
    <path fill="#56C271" d="m5 229.9 76.3-76.3H19.6c-8.1 0-14.6 6.5-14.6 14.6v61.7z" />
    <path fill="#2CCCD3" d="m43.2 229.9 38.1-38.2H43.2z" />
    <path fill="#FB3898" d="M92.3 229.9c42.2 0 76.3-34.2 76.3-76.3h-38c-21.2 0-38.3 17.2-38.3 38.3v38z" />
    <path fill="#FCCDBD" d="M130.6 153.5h38c0-42.2-34.2-76.3-76.3-76.3v38c0 21.2 17.2 38.3 38.3 38.3" />
    <path fill="#2795F2" d="M130.5.9H92.3v38.2c0 21.1 17.1 38.2 38.2 38.2s38.2-17.1 38.2-38.2S151.6.9 130.5.9m0 54.3c-8.9 0-16.1-7.2-16.1-16.1V22.9l16.1.1c8.9 0 16.1 7.2 16.1 16.1 0 8.8-7.2 16.1-16.1 16.1" />
    <path fill="#F4D660" d="M27 77.2c0-30 24.3-54.3 54.3-54.3V.9C39.2.9 5 35.1 5 77.2s34.2 76.3 76.3 76.3v-22c-30 0-54.3-24.3-54.3-54.3" />
    <path fill="#ED6B00" d="M38 77.2c0 23.9 19.4 43.3 43.3 43.3V33.9C57.4 33.9 38 53.3 38 77.2" />
  </svg>
)

SvgLogoWhite.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  className: string,
}

SvgLogoWhite.defaultProps = {
  height: '1em',
  width: '1em',
  className: '',
}

export default SvgLogoWhite

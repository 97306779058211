import React, { useEffect, useState, useContext } from "react"
import SiteContext from "../../../context/site.context"

import { func } from "prop-types"

import TextTitle from "../blocks/title/TextTitle"
import RoundedButton from "../../elements/buttons/RoundedButton"
import UnderlineButton from "../../elements/buttons/UnderlineButton"
import ButtonArrowLeft from "../../elements/buttons/ButtonArrowLeft"
import Dropdown from "../../elements/dropdown/Dropdown"
import Modal from "../modal/Modal"

import ContractListStyle from "./ContractList.style"
import EditAccount from "./EditAccount"

const ContractList = ({ handleNextStep }) => {
  const classNameID = `m-contract-list`
  const [options, setOptions] = useState([])
  const { currentIndex, setCurrentIndex, accounts } =
    useContext(SiteContext)

  const [showModal, setShowModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)

  const updateIndex = index => {
    setCurrentIndex(index)
  }
  const openModal = () => {
    setShowModal(true)
  }

  useEffect(() => {
    const theOptions = []

    if (accounts.length > 0) {
      for (let i = 0; i < accounts.length; i += 1) {
        const { label, accountNumber } = accounts[i]
        theOptions.push({ value: i, label: label, labelRight: accountNumber})

        if (i === currentIndex) {
          setSelectedValue({ value: i, label: label, labelRight: accountNumber})
        }
      }
    }
    setOptions(theOptions)
  }, [accounts, currentIndex])

  const editContract = () => {
    return <UnderlineButton onClick={openModal}>Editar</UnderlineButton>
  }

  return (
    <ContractListStyle className={classNameID}>
      <ButtonArrowLeft to="/cuenta" className={`${classNameID}__button-back`}>
        Regresar
      </ButtonArrowLeft>
      {options.length > 0 && (
        <>
          <TextTitle textBlue="Lista de" textGreen="contratos" />
          <div className={`${classNameID}__container_select`}>
            <Dropdown
              config={{
                options: options,
                value: selectedValue,
                onChange: e => {
                  updateIndex(e.value)
                  setSelectedValue(e)
                },
              }}
              className={`${classNameID}__select is-blue-dark-icon-pink `}
              id={`select__contract`}
            />
          </div>
        </>
      )}
      <div className={`${classNameID}__container_buttons`}>
        <RoundedButton
          className={`${classNameID}__button is-blue`}
          onClick={() => handleNextStep(1)}
        >
          Agregar
        </RoundedButton>
        {options.length > 0 && editContract()}
      </div>
      {showModal && (
        <Modal onClose={() => {setShowModal(false)}} >
          <EditAccount
            onClose={() => {
              setShowModal(false)
            }}
          />
        </Modal>
      )}
    </ContractListStyle>
  )
}

ContractList.propTypes = {
  handleNextStep: func,
}

ContractList.defaultProps = {
  handleNextStep: () => {},
}

export default ContractList

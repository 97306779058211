import React from 'react'
import { string, func, node, bool } from 'prop-types'

import { RoundedButtonStyled, LinkButtonStyled } from './RoundedButton.style'

const RoundedButton = ({ children, onClick, isDisabled, className, to }) => {
  const classNameID = `e-rounded-button`

  if (to) { 
    return (
      <LinkButtonStyled to={to} className={`${classNameID} ${className} ${isDisabled ? 'is-disabled' : ''}`}>
        {children && <>{children}</>}
      </LinkButtonStyled>
    )
  }

  return (
    <RoundedButtonStyled className={`${classNameID} ${className} ${isDisabled ? 'is-disabled' : ''}`} type="button" onClick={onClick}>
      {children && <>{children}</>}
    </RoundedButtonStyled>
  )
}

RoundedButton.propTypes = {
  children: node.isRequired,
  className: string,
  onClick: func,
  isDisabled: bool,
  to: string,
}

RoundedButton.defaultProps = {
  className: '',
  onClick: () => null,
  isDisabled: false,
  to: '',
}

export default RoundedButton

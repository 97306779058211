/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

function Seo({ description, title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const canonical = `${site.siteMetadata.siteUrl}${typeof location !== 'undefined' && location.pathname ? location.pathname : ''}`;
  const image = `${site.siteMetadata.image}`;

  return (
    <>
      <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="image" content={image} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="og:url" content={canonical} />
      <meta property="twitter:url" content={canonical} />
      <link rel="canonical" href={canonical} />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon-196x196.png`} sizes="196x196" />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon-96x96.png`} sizes="96x96" />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon-32x32.png`} sizes="32x32" />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon-16x16.png`} sizes="16x16" />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon-128.png`} sizes="128x128" />
      {children}
    </>
  )
}

export default Seo


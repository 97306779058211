import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const StyleCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
`

export const InputCheckbox = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 0;
  color: ${props => props.theme.colors.blueDark};
  position: relative;
  width: 100%;

  div {
    padding-left: 30px;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
  }
  &:before {
    border: 1px solid ${props => props.theme.colors.blue};
    border-radius: 50%;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
  }
  &:after {
    background: ${props => props.theme.colors.pink};
    border: 1px solid transparent;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    transform: scale(0);
  }
  &.is-active {
    &:after {
      transform: scale(1);
    }
  }
  &.input-error {
    &:before {
      border: 1px solid ${props => props.theme.colors.pink};
    }
  }
`

export const StyledInputError = styled.p`
  bottom: -22px;
  color: ${props => props.theme.colors.pink};
  font-size: 12px;
  font-weight: ${props => props.theme.fonts.fontSemiBold};
  left: 25px;
  position: absolute;

  @media ${device.lg} {
    font-size: 13px;
  }
`

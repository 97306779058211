import React, { useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import SiteContext from "../../../context/site.context"

import validate from "../../../helpers/formValidationRules"
import useForm from "../../../hooks/useForm"

import TextTitle from "../blocks/title/TextTitle"
import InputText from "../../elements/inputs/InputText"
import RoundedButton from "../../elements/buttons/RoundedButton"
import UnderlineButton from "../../elements/buttons/UnderlineButton"
import Checkbox from "../../elements/checkbox/Checkbox"
import Modal from "../modal/Modal"
import CustomAlert from "../../elements/alerts/CustomAlert"
import VerificationCode from "./VerificationCode"

import iconLoading from "../../../images/loading.png"

import { RegisterStyled } from "./Register.style"

const Register = () => {
  const classNameID = `m-register`
  const { loading, signUpAccount, error, sendVerificationCode, getVerify } =
    useContext(SiteContext)
  const [showVerificationCodeModal, setShowVerificationCodeModal] =
    useState(false)
  const [showModal, setShowModal] = useState(false)

  const data = {
    name: "",
    surnames: "",
    email: "",
    password: "",
    telefono: "",
    contrato: "",
    label: "",
    numMedidor: "",
    confirmPassword: "",
    acceptedPrivacyNotice: false,
  }

  const { inputs, handleInputChange, handleSubmit, errors } = useForm(
    data,
    formValidCallback,
    validate
  )

  async function formValidCallback(errorsForm) {
    if (Object.keys(errorsForm).length > 0) {
      return
    }

    const verification = await sendVerificationCode(inputs.telefono)
    if (verification.status === 200) {
      setShowVerificationCodeModal(true)
      return 
    }
    setShowModal(true)
  }

  const handleSendVerificationCode = async verificationCode => {
    const verify = await getVerify(verificationCode, inputs.telefono)
    if (verify.status === 200 && verify.data.message === "OK") {
      handleSingUpAccount()
      return
    }

    setShowModal(true)
  }

  const handleSingUpAccount = async () => {
    await signUpAccount(
      inputs.name,
      inputs.surnames,
      inputs.email,
      inputs.password,
      inputs.telefono,
      inputs.contrato,
      inputs.label,
      inputs.numMedidor
    )
    setShowVerificationCodeModal(false)
    setShowModal(true)
  }

  const handleCheckbox = active => {
    inputs.acceptedPrivacyNotice = active
  }

  return (
    <RegisterStyled className={classNameID}>
      <TextTitle textBlue="Registro" textGreen="" />
      <div className={`${classNameID}__container-form`}>
        <form
          id="form-recover-password"
          noValidate
          className={`${classNameID}__formulario`}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <InputText
            id="name"
            name="name"
            className={`${classNameID}__input`}
            type="text"
            placeholder="*Nombre completo"
            handleInputChange={handleInputChange}
            required
            error={errors.name}
          />
          <InputText
            id="surnames"
            name="surnames"
            className={`${classNameID}__input`}
            type="text"
            placeholder="*Apellidos"
            handleInputChange={handleInputChange}
            required
            error={errors.surnames}
          />
          <InputText
            id="email"
            name="email"
            className={`${classNameID}__input`}
            type="email"
            placeholder="*Tu Email"
            handleInputChange={handleInputChange}
            required
            error={errors.email}
          />
          <InputText
            id="telefono"
            name="telefono"
            className={`${classNameID}__input`}
            type="number"
            placeholder="*Teléfono"
            handleInputChange={handleInputChange}
            required
            error={errors.telefono}
          />
          <InputText
            id="label"
            name="label"
            className={`${classNameID}__input`}
            type="text"
            placeholder="*Alias del contrato"
            handleInputChange={handleInputChange}
            required
            error={errors.label}
          />
          <InputText
            id="contrato"
            name="contrato"
            className={`${classNameID}__input`}
            type="number"
            placeholder="*No. de contrato"
            handleInputChange={handleInputChange}
            required
            error={errors.contrato}
          />
          <InputText
            id="numMedidor"
            name="numMedidor"
            className={`${classNameID}__input`}
            type="text"
            placeholder="*No. medidor"
            handleInputChange={handleInputChange}
            required
            error={errors.numMedidor}
          />
          <InputText
            id="password"
            name="password"
            className={`${classNameID}__input`}
            type="password"
            placeholder="*Contraseña"
            handleInputChange={handleInputChange}
            required
            error={errors.password}
          />
          <InputText
            id="confirmPassword"
            name="confirmPassword"
            className={`${classNameID}__input`}
            type="password"
            placeholder="*Confirmar contraseña"
            handleInputChange={handleInputChange}
            required
            error={errors.confirmPassword}
          />
          <div className={`${classNameID}__container-terms`}>
            <Checkbox
              className={`${classNameID}__checkbox`}
              handleCheckbox={handleCheckbox}
              error={errors.acceptedPrivacyNotice}
            >
              <p>
                Acepto los <span> </span>
                <a
                  href="https://www.aguasdesaltillo.com/aviso-de-privacidad/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Términos y Condiciones de uso
                </a>
              </p>
            </Checkbox>
          </div>
          <div className={`${classNameID}__container-password-hint`} >
            <p>
              La contraseña debe tener al menos 8 caracteres, incluyendo
              mayúsculas, números y caracteres especiales.
            </p>
          </div>
          <div className={`${classNameID}__container-button`}>
            <RoundedButton
              className={`${classNameID}__button--send is-blue has-animation`}
              type="submit"
              onClick={handleSubmit}
              isDisabled={loading}
            >
              {loading ? (
                <>
                  <img src={iconLoading} alt="loading" /> Cargando...
                </>
              ) : (
                "Registrarme"
              )}
            </RoundedButton>
          </div>
        </form>
        <Link to="/">
          <UnderlineButton className={`${classNameID}__button--cancelar`}>
            Cancelar
          </UnderlineButton>
        </Link>
      </div>
      {showVerificationCodeModal && (
        <Modal onClose={() => {setShowVerificationCodeModal(false)}}>
          <VerificationCode onClick={handleSendVerificationCode} />
        </Modal>
      )}
      {showModal && (
        <Modal onClose={() => {setShowModal(false)}}>
          {error !== null ? (
            <CustomAlert
              title="¡Error!"
              description={error}
              buttonTitle="Intentar de nuevo"
              onClose={() => setShowModal(false)}
              isError={true}
            />
          ) : (
            <CustomAlert
              title="¡Registro exitoso!"
              description="Tu cuenta ha sido creada correctamente."
              buttonTitle="Aceptar"
              onClose={() => {
                setShowModal(false)
                navigate("/login")
              }}
            />
          )}
        </Modal>
      )}
    </RegisterStyled>
  )
}

export default Register

import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

export const ChanagePasswordStyled = styled.div`
  margin-top: 20px;
  padding: 0 16px;
  position: relative;
  margin-bottom: 100px;

  .m-change-password__button-back{
    margin-bottom: 30px;
  }

  .m-change-password__container-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 130px;

    .m-change-password__formulario {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .m-change-password__input{
        width: 100%;
        max-width: 380px;
      }

      .m-change-password__container-button{
        width: 100%;
        text-align: center;
        margin-top: 40px;

        .m-change-password__button--send{
          width: 258px;
          margin-bottom: 25px;
        }
      }

      .m-change-password__container-password-hint {
        margin-top: 20px;
        text-align: center; 
        max-width: 380px;
         
        p {
          font-size: 14px;
        }
      }
    }
  }

  @media ${device.sm} {
    padding: 0 42px;
    margin-bottom: 130px;

    .m-change-password__container-form {
      max-width: 780px;
      margin: 80px auto 140px;

      .m-change-password__formulario {
        flex-direction: column;
        align-items: center;
        .m-change-password__input{
          width: 370px;
        }

        .m-change-password__container-button  .m-change-password__button--send{
          width: 290px;
        }
      }
    }
  }

  @media ${device.md} {
    margin-top: 30px;
    padding: 0 50px;

    .m-change-password__button-back{
      margin-bottom: 0px;
    } 

    .m-change-password__container-form {
      width: 815px;
      max-width: 850px;
    }
  }

  @media ${device.lg} {
    margin-top: 70px;
    padding: 0 60px;

    .m-change-password__container-form {
      width: 100%;
      max-width: 1035px;
    }
  }

  @media ${device.xl} {
    width: 1744px;
    padding: 0;
    margin: 70px auto 150px;

    .m-change-password__container-form {
      max-width: 1138px;

      .m-change-password__formulario {
        .m-change-password__input{
          width: 430px;
          max-width: 430px;
        }

        .m-change-password__container-password-hint {
          max-width: 430px;
        
        }
      }
    }
  }
`

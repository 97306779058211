import React from "react"
import Seo from "../components/seo/Seo"
import DashboardOficinaVirtual from "../components/modules/dashboardOficinaVirtual/DashboardOficinaVirtual"

const Cuenta = () => {
  return <DashboardOficinaVirtual />
}

export default Cuenta

export const Head = () => <Seo title={"Mi cuenta"} />

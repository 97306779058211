import React from "react"
import SvgLogoDark from "../../elements/svgs/SvgLogoDark"
import FooterIconLink from "../../elements/links/FooterIconLink"
import SvgFacebook from "../../elements/svgs/social/SvgFacebook"
import SvgTwitter from "../../elements/svgs/social/SvgTwitter"
import SvgInstagram from "../../elements/svgs/social/SvgInstagram"
import SvgYoutube from "../../elements/svgs/social/SvgYoutube"

import FooterStyled from "./Footer.style"

const Footer = () => {
  const classNameID = `m-footer`
  return (
    <FooterStyled id={classNameID} className={classNameID}>
      <div className={`${classNameID}__container`}>
        <SvgLogoDark className={`${classNameID}__logo`} />
        <div className={`${classNameID}__social-media`}>
          <div className={`${classNameID}__social-title`}>Síguenos en:</div>
          <FooterIconLink href={'https://es-la.facebook.com/aguasdesaltillo'} target="_blank" className={`${classNameID}__social-link`}>
            <SvgFacebook fill="#FFF" />
          </FooterIconLink>
          <FooterIconLink href={'https://twitter.com/aguasdesaltillo?lang=es'} target="_blank" className={`${classNameID}__social-link`}>
            <SvgTwitter fill="#FFF" />
          </FooterIconLink>
          <FooterIconLink href={'https://www.youtube.com/channel/UC0M6ywuPQyG88ZzApUCoplA'} target="_blank" className={`${classNameID}__social-link`}>
            <SvgYoutube fill="#FFF" />
          </FooterIconLink>
          <FooterIconLink href={'https://www.instagram.com/aguasdesaltillo_'} target="_blank" className={`${classNameID}__social-link`}>
            <SvgInstagram fill="#FFF" />
          </FooterIconLink>
        </div>
      </div>
    </FooterStyled>
  )
}

export default Footer

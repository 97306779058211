import styled from 'styled-components';

export const PayVoucherStyled = styled.div`
  margin-top: 50px;
  margin-bottom: 130px;
  padding: 0 60px;
  text-align: center;

  .m-pay-voucher__container_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .m-pay-voucher__button {
      margin-bottom: 20px;
    }
  }

  .m-pay-voucher__summary {
    margin: 60px 0px;
    width: 100%;
   
    border: solid 2px #e0f0fe;
    border-radius: 10px;

    .m-pay-voucher__summary-row {
      display: flex;
      justify-content: space-between;
      padding: 12px 20px;
      border-bottom: solid 2px #e0f0fe;

      .m-pay-voucher__summary-name {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.blueDark};
      }

      .m-pay-voucher__summary-value {
        font-size: 22px;
        font-weight: ${({ theme }) => theme.fonts.fontBold};
        color: ${({ theme }) => theme.colors.blue};
        text-align: right;
        text-transform: capitalize;

        &.is-pink {
          color: ${({ theme }) => theme.colors.pink};
          margin-left: 5px;
        }
      } 
    }
    .m-pay-voucher__summary-row:last-child {
      border-bottom: none;
    }
  }

  .m-pay-voucher__logo-agsal {
    height: 65px;
    margin: 20px 0 40px;
    width: 150px;
  }

`
export const ButtonPrintStyle = styled.div`
  .m-pay-voucher__button {
    margin-bottom: 20px;
  }

  .print-voucher--hidden {
    display: none;
  }
`

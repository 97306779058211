import React from "react"
import { string, func, node, bool } from "prop-types"

import UnderlineButtonStyled from "./UnderlineButton.style"

const UnderlineButton = ({ children, onClick, isDisabled, className }) => {
  const classNameID = `e-underline-button`

  return (
    <UnderlineButtonStyled
      className={`${classNameID} ${className} ${
        isDisabled ? "is-disabled" : ""
      }`}
      type="button"
      onClick={onClick}
    >
      {children && <>{children}</>}
    </UnderlineButtonStyled>
  )
}

UnderlineButton.propTypes = {
  children: node.isRequired,
  className: string,
  onClick: func,
  isDisabled: bool,
}

UnderlineButton.defaultProps = {
  className: "",
  onClick: () => null,
  isDisabled: false,
}

export default UnderlineButton

import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const StepSummaryStyle = styled.div`

  .m-step-summary__button-back{
    margin-bottom: 30px;
  }

  .m-step-summary__container-summary {
    border: solid 2px #e0f0fe;
    border-radius: 10px;
    margin-top: 40px;
    width: 100%;
    

    .m-step-summary__summary-row {
      align-items: center;
      border-bottom: solid 2px #e0f0fe;
      display: flex;
      justify-content: space-between;
      padding: 12px 20px;

      .m-step-summary__summary-name {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.blueDark};
      }

      .m-step-summary__summary-value {
        font-size: 22px;
        font-weight: ${({ theme }) => theme.fonts.fontBold};
        color: ${({ theme }) => theme.colors.blue};
        text-align: right;

        &.is-pink {
          color: ${({ theme }) => theme.colors.pink};
          margin-left: 5px;
        }
      } 
    }
    .m-step-summary__summary-row:last-child {
      border-bottom: none;
    }

  }

  .m-step-summary__container_button {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 100px;
    text-align: center;

    .m-step-summary__button {
      width: 258px;      
    }
  }

  @media ${device.sm} {
    .m-step-summary__container_button  .m-step-summary__button {
      width: 290px;      
    }

    .m-step-summary__container-summary {
      margin: 60px auto 0;
      max-width: 650px;
    }
   
  }

  @media ${device.md} {
    .m-step-summary__button-back{
      margin-bottom: 0px;
    } 
  }

  @media ${device.lg} {
    .m-step-summary__container-summ {
      .m-step-summary__input {
        width: 430px;
        max-width: 430px;
      }
      .m-step-summary__button-back{
        margin-bottom: 0px;
      }
    }
  }

  @media ${device.xl} {
  
  }
`

export default StepSummaryStyle;

import React, { useEffect, useContext, useState } from "react"
import SiteContext from "../../../context/site.context"

import TextTitle from "../blocks/title/TextTitle"
import ButtonArrowLeft from "../../elements/buttons/ButtonArrowLeft"
import Dropdown from "../../elements/dropdown/Dropdown"
import HistoryTable from "../blocks/historyTable/HistoryTable"
import Modal from "../modal/Modal"
import CustomAlert from "../../elements/alerts/CustomAlert"
import ViewContractEmpty from "../contracts/ViewContractEmpty"
import LoadingView from "../loading/LoadingView"

import HistorialStyle from "./History.style"

const History = () => {
  const classNameID = `m-history`
  const [options, setOptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const {
    setCurrentIndex,
    currentIndex,
    accounts,
    getHistory,
    getSanitationInvoice,
    error,
    loadAccount,
    fetchAccounts,
  } = useContext(SiteContext)
  const [currentHistory, setCurrentHistory] = useState([])
  const [currentSanitation, setCurrentSanitation] = useState([])

  const updateIndex = index => {
    setCurrentIndex(index)
    fetchHistoryInvoices(index)
  }

  const fetchHistoryInvoices = async index => {
    const accountNumber = accounts[index].accountNumber

    try {
      const response = await getHistory(accountNumber)
      setCurrentHistory(response.data)

      const responseSanitation = await getSanitationInvoice(accountNumber)
      setCurrentSanitation(responseSanitation.data)
    } catch (error) {
      setShowModal(true)
    }
  }

  useEffect(() => {
    const theOptions = []
    if (accounts.length > 0) {
      updateIndex(currentIndex)
      for (let i = 0; i < accounts.length; i += 1) {
        const { label, accountNumber } = accounts[i]
        theOptions.push({ value: i, label: label, labelRight: accountNumber })
      }
    }
    setOptions(theOptions)
  }, [accounts])

  useEffect(() => {
    if (loadAccount === null && accounts.length === 0) {
      fetchAccounts()
    }
  }, [loadAccount])

  return (
    <HistorialStyle className={classNameID}>
      <ButtonArrowLeft to="/cuenta" className={`${classNameID}__button-back`}>
        Regresar
      </ButtonArrowLeft>
      {loadAccount && <LoadingView />} 
      {options.length > 0 && !loadAccount && (
        <>
          <TextTitle textBlue="Historial de" textGreen="Consumo" />
          <div className={`${classNameID}__container_select`}>
            <Dropdown
              config={{
                options: options,
                defaultValue: options[currentIndex],
                onChange: e => updateIndex(e.value),
                placeholder: "Selecciona un contrato",
              }}
              className={`${classNameID}__select is-blue-dark-icon-pink `}
              id={`select__contract`}
            />
          </div>
          <HistoryTable
            history={currentHistory}
            historyTitle="Agua"
            id="table-water"
          />
          <HistoryTable
            history={currentSanitation}
            historyTitle="Saneamiento"
            id="table-sanitation"
            isSanitation
          />
        </>
      )}
      {loadAccount !== null && !loadAccount && accounts.length === 0 && (
        <ViewContractEmpty />
      )}
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false)
          }}
        >
          <CustomAlert
            onClose={() => {
              setShowModal(false)
            }}
            title="Error de conexión"
            description={error}
            buttonTitle="Aceptar"
            isError={true}
          />
        </Modal>
      )}
    </HistorialStyle>
  )
}

export default History

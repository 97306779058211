import React, { useState, useContext, useEffect } from "react"
import { navigate, Link } from "gatsby"

import SiteContext from "../../../context/site.context"
import validate from "../../../helpers/formValidationRules"
import useForm from "../../../hooks/useForm"
import { handleLogin, isLoggedIn, veryfy2FA } from "../../../services/auth"

import TextTitle from "../blocks/title/TextTitle"
import InputText from "../../elements/inputs/InputText"
import RoundedButton from "../../elements/buttons/RoundedButton"
import UnderlineButton from "../../elements/buttons/UnderlineButton"
import Modal from "../modal/Modal"
import CustomAlert from "../../elements/alerts/CustomAlert"
import VerificationCode from "../register/VerificationCode"

import wsErrors from '../../../../static/data/errors.json'

import iconLoading from "../../../images/loading.png"

import { LoginStyled, StyledFormContainer } from "./Login.style"

const Login = () => {
  const classNameID = `m-login`
  const {setloading } = useContext(SiteContext)
  const [isSending, setIsSending] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showVerificationCodeModal, setShowVerificationCodeModal] = useState(false)
  const [webServicesError, setWebServicesError] = useState("")
  const [sessionExpiredModal, setSessionExpiredModal] = useState(false)
  
  const data = {
    email: "",
    loginPassword: "",
  }

  const { inputs, handleInputChange, handleSubmit, errors } = useForm(
    data,
    formValidCallback,
    validate
  )

  async function formValidCallback(errorsForm) {

    if (Object.keys(errorsForm).length > 0) {
      return
    }

    setIsSending(true)

    try {
      const response = await handleLogin({ email: inputs.email, password: inputs.loginPassword });
      setIsSending(false)

      if (response.tfaEnabled) {
        setShowVerificationCodeModal(true)
      }
      if (isLoggedIn()) {
        initSession()
      }
    
    } catch (error) { 
      const errorType = error.message
      const errorObject = Object.values(wsErrors).find(e => e.error === errorType);

      setWebServicesError(errorObject?.message || wsErrors['unexpected_error'].message)
      setShowModal(true)
      setIsSending(false)
    }
  }

  const handleSendVerificationCode = async verificationCode => {
    setloading(true)
    try {
      await veryfy2FA(verificationCode)
      setShowVerificationCodeModal(false)
      setloading(false)
      if(isLoggedIn()) {
        initSession()
      }
    } catch (error) {
      const errorType = error.message
      const errorObject = Object.values(wsErrors).find(e => e.error === errorType);

      setWebServicesError(errorObject?.message || wsErrors['unexpected_error'].message)
      setloading(false)
    }
    
  }

  const initSession = () => {
    navigate("/cuenta");
  }

  useEffect(() => { 
    const sessionExpired = localStorage.getItem('sessionExpired');
    if (sessionExpired !== undefined && sessionExpired === 'true') {
      setSessionExpiredModal(true)
      localStorage.setItem('sessionExpired', 'false');
    }
  }, [])

  const handleSessionExpired = () => { 
    setSessionExpiredModal(false)
    localStorage.setItem('sessionExpired', 'false');
  }

  return (
    <LoginStyled id="login" className={classNameID}>
      <TextTitle textBlue="Inicia" textGreen="Sesion" />
      <StyledFormContainer>
        <form
          id="form-login"
          noValidate
          className={`${classNameID}__formulario`}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <InputText
            id="email"
            name="email"
            className={`${classNameID}__input`}
            type="email"
            placeholder="*Tu Email"
            handleInputChange={handleInputChange}
            required
            error={errors.email}
          />
          <div className={`${classNameID}__container-last-row`}>
            <InputText
              id="loginPassword"
              name="loginPassword"
              className={`${classNameID}__input`}
              type="password"
              placeholder="*Contraseña"
              handleInputChange={handleInputChange}
              required
              error={errors.loginPassword}
            />
            <div className={`${classNameID}__container-button-text`}>
              <Link to="/recuperar-contrasena">¿Olvidaste tu contraseña ?</Link>
            </div>
          </div>
          <div className={`${classNameID}__container-button`}>
            <RoundedButton
              className={`${classNameID}__button--send is-blue has-animation`}
              type="submit"
              onClick={handleSubmit}
              isDisabled={isSending}
            >
              {isSending ? (
                <>
                  <img src={iconLoading} alt="loading" /> cargando...
                </>
              ) : (
                "Entrar"
              )}
            </RoundedButton>
          </div>
        </form>
        <Link to="/registro"> 
          <UnderlineButton className={`${classNameID}__button--register`}>
            Crear una cuenta
          </UnderlineButton>
        </Link>
      </StyledFormContainer>
      {showVerificationCodeModal && (
        <Modal onClose={() => {setShowVerificationCodeModal(false)}} >
          <VerificationCode onClick={handleSendVerificationCode} errorMessage={webServicesError} />
        </Modal>
      )}
      {showModal && (
        <Modal onClose={() => {setShowModal(false)}} >
          <CustomAlert
            onClose={() => { setShowModal(false) }}
            title="Error al iniciar sesión"
            description={webServicesError}
            buttonTitle="Aceptar"
            isError={true}
          />
        </Modal>
      )}
      {
        sessionExpiredModal && (
          <Modal onClose={ handleSessionExpired } >
          <CustomAlert
            onClose={ handleSessionExpired }
            title="Tu sesión ha expirado"
            description={"Por favor inicia sesión de nuevo"}
            buttonTitle="Aceptar"
            isError={true}
          />
        </Modal>
        )
      }
    </LoginStyled>
  )
}

export default Login

import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ModuleStyled = styled.div`
  margin: 80px 0 130px 0;
  padding: 0 16px;
  position: relative;
  text-align: center;

  .m-not-found__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .m-not-found__image {
    max-width: 400px;
    width: 100%;
  }
  .m-not-found__image-xl {
    display: none;
  }
  .m-not-found__title {
    color: ${props => props.theme.colors.pink};
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 30px;
    font-weight: ${props => props.theme.fonts.fontExtraBold};
    line-height: 1.06;
    margin-top: 20px;
  }
  .m-not-found__description {
    color: ${props => props.theme.colors.blue};
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 16px;
    font-weight: ${props => props.theme.fonts.fontRegular};
    line-height: 1.375;
    margin-top: 20px;
  }
  .m-not-found__btn {
    display: block;
    margin-top: 35px;
    width: 258px;
  }

  @media ${device.sm} {
    margin: 0;
    padding: 0; 
    .m-not-found__container {
      margin: 0 -1.5rem;
      min-height: 930px;
      padding: 0;
    }
    .m-not-found__image {
      max-width: 550px;
    }
    .m-not-found__title {
      font-size: 38px;
      line-height: 1;
      margin-top: 30px;
    }
    .m-not-found__description {
      line-height: 1;
      margin-top: 15px;
    }
    .m-not-found__btn {
      margin-top: 75px;
      width: 290px;
    }
  }

  @media ${device.md} {
    .m-not-found__container {
      margin: 0;
      min-height: 660px;
    }
    .m-not-found__image {
      max-width: 530px;
    }
    .m-not-found__title {
      margin-top: 20px;
    }
    .m-not-found__btn {
      margin-top: 55px;
    }
  }

  @media ${device.lg} {
    .m-not-found__image {
      max-width: 575px;
    }
    .m-not-found__title {
      font-size: 42px;
      margin-top: 10px;
    }
    .m-not-found__description {
      margin-top: 15px;
    }
    .m-not-found__btn {
      margin-top: 30px;
    }
  }

  @media ${device.xl} {
 
    .m-not-found__container {
      min-height: 900px;
    }
    .m-not-found__image-xl {
      display: block;
      max-width: 736px;
      width: 100%;
    }
    .m-not-found__image {
      display: none;
    }
    .m-not-found__title {
      font-size: 46px;
      margin-top: 40px;
    }
    .m-not-found__description {
      font-size: 18px;
    }
    .m-not-found__btn {
      margin-top: 50px;
    }
  }
`
export default ModuleStyled

import * as React from "react"

function SvgCloseEye() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width={20} height={16} viewBox="0 0 512 512" >
      <path d="M285.8 116.7c-9.8-1.1-19.7-1.7-29.8-1.8-44 .2-89.5 10.9-132.7 31.4-32 15.8-63.2 38.2-90.3 65.8-13.3 14.1-30.3 34.5-33 56.2.3 18.8 20.5 42.1 33 56.2 25.4 26.5 55.8 48.2 90.3 65.8 1.2.6 2.4 1.1 3.5 1.7l-32 55.9 43.5 25.7L373.6 64l-41.9-25.6-45.9 78.3zm99.3 27.9-32 55.4c14.7 19.1 23.4 42.7 23.4 68.2 0 63.7-54 115.3-120.6 115.3-2.9 0-5.7-.3-8.5-.5l-21.2 36.6c9.8 1 19.6 1.8 29.7 1.9 44-.2 89.5-11 132.6-31.4 32-15.8 63.3-38.2 90.3-65.8 13.3-14.1 30.3-34.5 33-56.2-.3-18.8-20.5-42.1-33-56.2-25.4-26.5-55.8-48.2-90.3-65.8-1-.4-2.2-.9-3.4-1.5zM256 153c2.9 0 5.8.1 8.7.3l-24.8 42.9c-34.8 7.1-60.9 36.6-60.9 72.1 0 8.9 1.6 17.4 4.7 25.3l-24.8 43c-14.7-19.1-23.4-42.8-23.4-68.3C135.4 204.6 189.4 153 256 153zm72.1 90.4-55.8 96.7c34.6-7.2 60.5-36.6 60.5-71.9-.1-8.7-1.8-17-4.7-24.8z" />
  </svg>
  )
}

export default SvgCloseEye

import React from 'react'
import Select, { components } from 'react-select'
import { number, string, oneOf, oneOfType, arrayOf, shape } from 'prop-types'

import StyleDropdown from './Dropdown.style'

const Dropdown = ({ config, className, id }) => {
  const classNameID = `m-dropdown`

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="react-select__no-results">No hay resultados</span>
      </components.NoOptionsMessage>
    )
  }

  const Options = props => {
    return (
      <components.Option {...props}>
        <div className="react-select__container-options">
          {props.data.label}
          {props.data.labelRight && <span>{props.data.labelRight}</span>}
        </div>
      </components.Option>
    );
  };

  const SingleValue = ({ data, ...props }) => (
    <components.SingleValue {...props}>
      <div className="react-select__container-single-values">
        <span>{data.label}</span>
        {data.labelRight && <span>{data.labelRight}</span>}
      </div>
    </components.SingleValue>
  );

  const defaultConfig = {
    iconType: 'none',
    placeholder: 'Seleccione',
    defaultInputValue: '',
    defaultValue: null,
    // hideSelectedOptions: true,
    menuShouldScrollIntoView: true,
    classNamePrefix: 'react-select',
    components: { NoOptionsMessage, Option: Options, SingleValue },
    styles: {
      option: provided => ({
        ...provided,
        color: '#000000',
      }),
      control: () => ({
        color: '#E9E9E9',
        width: '100%',
      }),
      menu: provided => ({
        ...provided,
        color: '#E9E9E9',
      }),
    },
  }
  const finalConfig = { ...defaultConfig, ...config }

  return (
    <StyleDropdown id={id} className={`${classNameID} ${className} ${finalConfig.iconType !== 'none' ? 'has-icon' : ''}`}>
      <Select {...finalConfig} />
    </StyleDropdown>
  )
}

Dropdown.propTypes = {
  config: shape({
    iconType: oneOf(['none',]),
    options: arrayOf(
      shape({
        value: oneOfType([string, number]),
        label: string,
        labelRight: string,
      })
    ),
  }),
  className: string,
  id: string,
}

Dropdown.defaultProps = {
  config: {},
  className: '',
  id: '',
}

export default Dropdown

import React, { useEffect, useState, useContext } from "react"
import { func } from "prop-types"
import SiteContext from "../../../context/site.context"
import { summary } from "../../../services/payment"

import TextTitle from "../blocks/title/TextTitle"
import RoundedButton from "../../elements/buttons/RoundedButton"
import ButtonArrowLeft from "../../elements/buttons/ButtonArrowLeft"
import Dropdown from "../../elements/dropdown/Dropdown"
import Modal from "../modal/Modal"
import CustomAlert from "../../elements/alerts/CustomAlert"

import wsErrors from "../../../../static/data/errors.json"

import iconLoading from "../../../images/loading.png"

import StepPayStyle from "./StepPay.style"

const StepPay = ({ handleNextStep }) => {
  const classNameID = `m-step-pay`
  const [options, setOptions] = useState([])
  const {
    currentIndex,
    setCurrentIndex,
    accounts,
    loading,
    setloading,
    setDetailsSummary,
  } = useContext(SiteContext)
  const [invoicePaid, setInvoicePaid] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const updateIndex = index => {
    setCurrentIndex(index)
    setInvoicePaid(false)
  }

  const handleClick = async () => {
    const { accountNumber } = accounts[currentIndex]
    setloading(true)
    try {
      const response = await summary(accountNumber)
      const { data } = response

      setDetailsSummary(data)
      setloading(false)
      if (data.status === 3) {
        setInvoicePaid(true)
      } else {
        handleNextStep(1)
      }
    } catch (error) {
      const errorType = error?.response?.data.error
      const errorObject = Object.values(wsErrors).find(
        e => e.error === errorType
      )
      const errorMessage =
        errorObject?.message || wsErrors["unexpected_error"].message
      setErrorMessage(errorMessage)
      setloading(false)
      setShowModal(true)
    }
  }

  useEffect(() => {
    const theOptions = []
    if (accounts.length > 0) {
      for (let i = 0; i < accounts.length; i += 1) {
        const { label, accountNumber } = accounts[i]
        theOptions.push({ value: i, label: label, labelRight: accountNumber })
      }
    }
    setOptions(theOptions)
  }, [accounts])

  return (
    <StepPayStyle className={classNameID}>
      <ButtonArrowLeft to="/cuenta" className={`${classNameID}__button-back`}>
        Regresar
      </ButtonArrowLeft>
      {options.length > 0 && (
        <>
          <TextTitle textBlue="Pago en" textGreen="línea" />
          <div className={`${classNameID}__container_select`}>
            <Dropdown
              config={{
                options: options,
                defaultValue: options[currentIndex],
                onChange: e => updateIndex(e.value),
              }}
              className={`${classNameID}__select is-blue-dark-icon-pink `}
              id={`select__contract`}
            />
          </div>
          {invoicePaid && (
            <div className={`${classNameID}__message-amount`}>
              <p>Contrato al corriente de pago</p>
            </div>
          )}
          <div className={`${classNameID}__container_button`}>
            <RoundedButton
              className={`${classNameID}__button is-blue`}
              onClick={handleClick}
              isDisabled={loading || invoicePaid}
            >
              {loading ? (
                <>
                  <img src={iconLoading} alt="loading" /> cargando...
                </>
              ) : (
                "Continuar"
              )}
            </RoundedButton>
          </div>
        </>
      )}
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false)
          }}
        >
          {
            <CustomAlert
              onClose={() => {
                setShowModal(false)
              }}
              title="Error ¿Qué pasó?"
              description={errorMessage}
              buttonTitle="Intentar de nuevo"
              isError={true}
            />
          }
        </Modal>
      )}
    </StepPayStyle>
  )
}

StepPay.propTypes = {
  handleNextStep: func,
}

StepPay.defaultProps = {
  handleNextStep: () => {},
}

export default StepPay

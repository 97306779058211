import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const DashboardStyled = styled.div`
  margin-top: 50px;

  .m-dashboard__container-cards {
    padding: 50px 16px 100px 16px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  @media ${device.sm} {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .m-dashboard__container-cards {
      padding: 100px 30px;
    }

  }

  @media ${device.md} {
    margin-top: 115px;
    .m-dashboard__container-cards {
      padding: 100px 0;
      width: 800px;
      margin: 0 auto;
    }
  }

  @media ${device.lg} {
    .m-dashboard__container-cards {
      width: 1100px;
    }
  }

  @media ${device.xl} {
    .m-dashboard__container-cards {
      width: 1280px;
    }
  }
`;


export default DashboardStyled;

import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const ContractListStyle = styled.div`
  .m-contract-list__button-back{
    margin-bottom: 30px;
  }

  .m-contract-list__container_buttons {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .m-contract-list__button {
      width: 258px;  
      margin-bottom: 20px;    
    }
  }

  .m-contract-list__container_select {
    max-width: 380px;
    margin: 60px auto 0 auto;
  }

  .m-contract-list__loading {
    width: 100%;
    text-align: center;
  }
  @media ${device.sm} {

    .m-contract-list__container_select {
      max-width: 370px;
      margin: 60px auto 0 auto;
      max-height: 70px;
    }

    .m-contract-list__container_buttons  .m-contract-list__button {
      width: 290px;      
    }
   
  }

  @media ${device.md} {
    .m-contract-list__button-back{
      margin-bottom: 0px;
    } 
  }

  @media ${device.lg} {
    .m-contract-list__container_select {
      margin: 70px auto 0 auto;
    }
  }

  @media ${device.xl} {
    .m-contract-list__container_select {
      max-width: 430px;
      margin: 80px auto 0 auto;
    }
  }
`
export default ContractListStyle;

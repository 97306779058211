import axios from 'axios';
import { navigate } from 'gatsby';
import { getAccessToken, logout } from './auth';

const baseUrl = process.env.GATSBY_BASE_URL

const apiWebServices = axios.create({
  baseURL: baseUrl,
});

apiWebServices.interceptors.request.use(
  async config => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => Promise.reject(error)
);

apiWebServices.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.setItem('sessionExpired', 'true');
      logout(() => {
        navigate('/login');
      })
    }
    return Promise.reject(error);
  }
);


export default apiWebServices;

import axios from 'axios';
import { navigate } from 'gatsby';
import apiWebServices from './api-web-services';
import { jwtDecode } from 'jwt-decode';

export const isBrowser = () => typeof window !== "undefined";

const baseUrl = process.env.GATSBY_BASE_URL
const accounts = []

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {};

const setUser = user =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user));

export const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    const value = localStorage.getItem('com.aguasdesaltillo.accessToken');
    if (value === "null") {
      return null;
    }
    return value;
  }
  return null;
};
export const setAccessToken = (token) => {
  localStorage.setItem('com.aguasdesaltillo.accessToken', token);
};
export const setRefreshToken = (newToken) => {
  localStorage.setItem('com.aguasdesaltillo.refreshToken', newToken);
}

export const getRefreshToken = () => {
  const value = localStorage.getItem('com.aguasdesaltillo.refreshToken');
  if (value === "null") {
    return null;
  }
  return value;
}

export const veryfy2FA = async (verificationCode) => {
  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/auth/verify`,
      { verificationCode },
    );
    const { data } = response;

    accounts.push(data.user.accounts)
    setUser(data.user);
    return data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
}

export const resendOtp = async () => {
  try {
    const response = await apiWebServices.post(`${baseUrl}/api/v1/auth/resend-otp`,
      {},
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
}

export const handleLogin = async ({ email, password }) => {

  try {
    const response = await axios.post(`${baseUrl}/api/v1/auth/login`,
      { email, password },
      { headers: {'Content-Type': 'application/json'}},
    );

    const { data } = response;
    setAccessToken(data.accessToken)
    setRefreshToken(data.refreshToken)

    if (data.tfaEnabled) {
      return data
    }

    accounts.push(data.user.accounts)
    setUser(data.user); // save user data on localStorage

    return data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const getAccounts = () => {
  return accounts
};

const decodeToken = (token) => {
  return jwtDecode(token);
};

export const isTokenExpired = (token) => {
  const decoded = decodeToken(token);
  const currentTime = Date.now() / 1000; // Convert to Unix timestamp (seconds)
  
  if (decoded.exp < currentTime) {
    // Token has expired
    return true;
  } else {
    // Token is still valid
    return false;
  }
};

export const isLoggedIn = () => {
  const user = getUser();
  let accessToken = getAccessToken();
  const isExpired = accessToken !== null ? isTokenExpired(accessToken) : true;
  if (isExpired && user !== null && accessToken !== null) {
    logout(() => navigate(`/`))
  }

  return !!user.name && !isExpired;
};


const handleRefreshToken = async () => { 
  try {
    const token = getRefreshToken();
    const response = await axios.get(`${baseUrl}/api/v1/auth/refresh`,
      { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }, }
    );

    return response.data.accessToken;
  } catch (error) { 
    if (error.response.status === 401) {
      localStorage.setItem('sessionExpired', 'true');
      navigate('/login');
    }
  }
}

export const logout = async (callback) => {
  try {
    const accessToken = await handleRefreshToken();
    await axios.post(`${baseUrl}/api/v1/auth/logout`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      },
    );

  } catch (error) {
    console.log('error-->', error)
  } finally {
    setUser({});
    setAccessToken(null);
    setRefreshToken(null);
    callback();
  }
};

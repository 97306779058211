import styled from "styled-components";
import { device } from "../../../theme/mediaQueries";

export const ModalCard = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  display: block;
  min-height: 500px;
  margin: 0 auto;
  padding-bottom: 30px;
  position: relative;
  text-align: center;
  width: 90%;

  @media ${device.sm} {
    width: 450px;
    height: 550px;
  }

  @media ${device.lg} {
    width: 500px;
    height: 600px;
  }

  .verification-code__error {
    color: ${props => props.theme.colors.pink};
    font-size: 14px;
    font-weight: ${props => props.theme.fonts.fontSemiBold};
    opacity: 0;

    &.error-visible {
      opacity: 1;
    }
  }

  .verification-code__button{
    margin: 0 auto;
    display: flex;
    min-width: 250px;
  }

  .verification-code__otp-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 10px 30px;
    max-width: 380px;
  }

  .verification-code__otp-input {
    color: ${props => props.theme.colors.blueDark};
    border-radius: 16px 0;
    border: 2px solid  #8dc2f7;
    box-sizing: border-box;
    display: block;
    font-weight: ${props => props.theme.fonts.fontSemiBold};
    font-size: 20px;
    height: 65px;
    width: 45px !important;
    -moz-appearance: textfield;

    &.is-error {
      border: 2px solid  ${props => props.theme.colors.pink};
    }
  }

  .verification-code__otp-input:focus-visible {
    outline: 1px auto ${props => props.theme.colors.blue};
  }

  .verification-code__otp-input::-webkit-inner-spin-button,
  .verification-code__otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`


export const StyleModalText = styled.div`
  width: 100%;
  padding: 45px 30px;
  text-align: center;
  .verification-code__title{
    color: ${props => props.theme.colors.blue};
    font-size: 26px;
    font-weight: ${props => props.theme.fonts.fontExtraBold};
    margin-bottom: 20px;
  }
  p{
    font-weight: ${props => props.theme.fonts.fontBold};
    max-width: 300px;
    margin: 0 auto;
  }

  @media ${device.sm} {
    .verification-code__title{
      font-size: 32px;
    }
  }

  @media ${device.lg} {
    padding: 55px 30px;
  }
`

import * as React from "react"

function SvgAlert(props) {
  return (
    <svg {...props} viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg" width={90} height={79} fill="none">
    <g clipPath="url(#svg-alert)">
      <path
        fill="#F964B2"
        d="M45 78.003H9.39c-7.322 0-11.819-8.019-8-14.267L18.835 35.2 37 5.483c3.656-5.982 12.344-5.982 16.001 0L71.167 35.2l17.444 28.536c3.82 6.248-.677 14.267-8 14.267H45Z"
      />
      <path
        fill="#FB3898"
        d="M45.057.999C41.953.98 38.84 2.474 37 5.483L18.835 35.2 1.39 63.736c-3.819 6.248.678 14.267 8 14.267h35.665V1h.002Z"
      />
      <path
        fill="#fff"
        d="M49.084 24.382h-8.17c-.563 0-1.02.457-1.02 1.021v23.491c0 .564.457 1.022 1.02 1.022h8.17c.564 0 1.02-.458 1.02-1.022v-23.49c0-.564-.456-1.022-1.02-1.022ZM49.084 55.031h-8.17c-.563 0-1.02.457-1.02 1.021v8.17c0 .563.457 1.02 1.02 1.02h8.17c.564 0 1.02-.457 1.02-1.02v-8.17c0-.564-.456-1.02-1.02-1.02Z"
      />
      <path
        fill="#D9F3FA"
        d="M45.057 24.382h-4.14c-.564 0-1.022.458-1.022 1.021v23.491c0 .564.458 1.022 1.021 1.022h4.141V24.382ZM45.057 55.031h-4.14c-.564 0-1.022.458-1.022 1.021v8.17c0 .563.458 1.02 1.021 1.02h4.141v-10.21Z"
        opacity={0.4}
      />
    </g>
    <defs>
      <clipPath id="svg-alert">
        <path fill="#fff" d="M0 .997h90v77.006H0z" />
      </clipPath>
    </defs>
  </svg>
  )
}

export default SvgAlert

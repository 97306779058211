/* eslint-disable no-shadow */
import { useState } from 'react'

const useForm = (initialValues, callback, validate) => {
  const [inputs, setInputs] = useState(initialValues)
  const [errors, setErrors] = useState({})
  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    setErrors(validate(inputs))
    callback(validate(inputs))
  }
  const handleInputChange = (event) => {
    if (event?.persist) event.persist()
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }))
    for (const key in errors){
      if(event.target.name === key)
      errors[key] = ''
    }
  }

  const resetInputs = () => {
    setInputs(initialValues)
  }

  return {
    inputs,
    errors,
    resetInputs,
    handleSubmit,
    handleInputChange,
    
  }
}
export default useForm